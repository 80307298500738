import React, { Component } from "react";
import fazanlogo from "./fazanlogo.png";
import huawei from "./AppGallery.png";
import google from "./googleplay.png";
import fazanlogofull from "./fazanlogofull.png";
import lnv500 from "./lnv500.png";
import Clanovi from "./components/clanovi";
import Izvještaji from "./components/izvjestaji";
import IzvještajNeplaćeni from "./components/izvjestajNeplaceni";
import IzvještajiUlov from "./components/izvjestajiUlov";
import NoviClan from "./components/noviClan";
import firebase from "./firebase.js";
import { login } from "./funkcije.js";
import Form from "react-bootstrap/Form";
import md5 from "md5";

class AppLovac extends Component {
  state = {
    izbor: "",
    prva: "",
  };

  header = {
    height: "110px",
    width: "100%",
    background: "#BEE9BF",
    position: "fixed",
    "z-index": "10",
    "vertical-align": "middle",
    "text-align": "center",
  };
  header2 = {
    height: "100%",
    width: "100%",
    background: "#BEE9BF",
    position: "fixed",
    overflow: "scroll",
    "z-index": "10",
    "vertical-align": "middle",
    "text-align": "center",
  };
  log = {
    height: "37px",
    width: "150px",
    "vertical-align": "middle",
    "text-align": "center",
    margin: "10px",
  };

  skini = {
    color: "#1b8935",
    "font-style": "italic",
  };

  slika = {
    height: "90px",
    width: "90px",
  };
  download = {
    height: "80px",
    width: "260px",
  };
 
  slika2 = {
    "margin-top": "120px",
    "margin-bottom": "10px",
    height: "300px",
    width: "300px",
  };

  slika3 = {
    height: "90px",
    width: "90px",
  };

  button = {
    width: "110px",
  };

  body = {
    "margin-top": "150px",
  };

  componentDidMount() {
    this.ss();
  }

  ss = () => {
    let local="https://www.app.fazandoboj.com/LovacNet.apk"
    let appgalery="https://appgallery.cloud.huawei.com/ag/n/app/C103126611?locale=en_GB&source=appshare&subsource=C103126611"
    let playstore="https://play.google.com/store/apps/details?id=com.bitmedic.lovac.net.fazan"
    this.setState({
      prva: (
        <div style={this.header2}>
          <img src={fazanlogofull} style={this.slika2} />
          <br />
          <input
            style={this.log}
            type="text"
            id="korisnik"
            placeholder="korisnik"
          />

          <input
            style={this.log}
            type="password"
            id="lozinka"
            placeholder="lozika"
          />
          <button
            className="btn btn-success m-1"
            onClick={() => this.login()}
            style={this.button}
          >
            Prijavi se
          </button>
          <br />
          <br />
          <a href="https://appgallery.cloud.huawei.com/ag/n/app/C103126611?locale=en_GB&source=appshare&subsource=C103126611"><img className="m-3 mt-4" src={huawei} style={this.download} /></a>
          <br />
          <a href="https://play.google.com/store/apps/details?id=com.bitmedic.lovac.net.fazan"><img className="m-3" src={google} style={this.download}  /></a>

        </div>
      ),
    });
  };

  login = () => {
    let korisnik = document.getElementById("korisnik").value;

    let email = korisnik + "@fazandoboj.com";

    let lozinka = document.getElementById("lozinka").value;

    //alert(md5(""));
    if (md5(korisnik) == "5bca5895e3ca0cb9c01cafc700a4c8c8") {
      login(email, lozinka)
        .then((rezultat) => {
          this.start();
        })
        .catch(function (error) {
          alert("Pogrešan korisnik ili lozinka");
          document.getElementById("korisnik").value = "";
          document.getElementById("lozinka").value = "";
        });
    } else {
      alert("Nemate ovlaštenja za pristup aplikaciji!");
    }
  };

  start = () => {
    this.setState({
      prva: (
        <div>
          <div style={this.header}>
            <img className="m-2 mr-5" src={fazanlogofull} style={this.slika} />

            <button
              className="btn btn-success m-1"
              onClick={() => this.promeni(<Clanovi />)}
              style={this.button}
            >
              Članovi
            </button>
            <button
              className="btn btn-success m-1"
              onClick={() => this.promeni(<NoviClan />)}
              style={this.button}
            >
              Novi član
            </button>

            <button
              className="btn btn-success m-1"
              style={this.button}
              onClick={() => this.promeni(<Izvještaji />)}
            >
              Uplate
            </button>

            <button
              className="btn btn-success m-1"
              style={this.button}
              onClick={() => this.promeni(<IzvještajNeplaćeni />)}
            >
              Neplaćeno
            </button>

            <button
              className="btn btn-success m-1"
              style={this.button}
              onClick={() => this.promeni(<IzvještajiUlov />)}
            >
              Odstrel
            </button>
            <img className="m-2 ml-5" src={lnv500} style={this.slika3} />

          </div>
        </div>
      ),
    });
    this.setState({ izbor: <Clanovi /> });
  };

  promeni = (pp) => {
    this.setState({ izbor: pp });
  };

  render() {
    return (
      <div>
        <div>{this.state.prva}</div>
        <div
          style={{
            clear: "both",
            position: "relative",
            top: "105px",
            "z-index": "9",
          }}
        >
          {this.state.izbor}
        </div>
      </div>
    );
  }
}

export default AppLovac;
