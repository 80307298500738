import firebase from "./firebase.js";
import drugi from "./firebase2";

export async function login(email, pass) {
  var rezultat = await firebase
    .auth()
    .signInWithEmailAndPassword(email, pass)
    .catch(function (error) {});
  return rezultat;
}

export async function dodajKorisnika(email, pass) {
  let uu = await drugi
    .auth()
    .createUserWithEmailAndPassword(email, pass)
    .catch(function (error) {
      alert("Greška pri kreiranju korisnika");
    });

  return uu;
}

export async function upisiClana(
  status,
  ime,
  prezime,
  sifra,
  funkcija,
  imeRoditelja,
  jmbg,
  email,
  telefon,
  mjestoRodjenja,
  adresa,
  revir,
  sekcija,
  zanimanje,
  firma,
  datumClanstva,
  datumRodjenja,
  invaliditet,
  nacija,
  mjestoStanovanja,
  sprema,
  lozinka,
  uid,
  datumLovackog
) {
  firebase
    .database()
    .ref("/clan/" + uid)
    .set({
      adresa: adresa,

      datumC: datumClanstva,

      datumR: datumRodjenja,

      email: email,

      firma: firma,

      funkcija: funkcija,

      ime: ime,

      jmbg: jmbg,

      lovacInvaliditetK: invaliditet,

      lovacNacijaK: nacija,

      lovacRevirK: revir,

      lovacSekcijaK: sekcija,

      lovacSpremaK: sprema,

      lovacStatusK: status,

      lovacZanimanjeK: zanimanje,

      lozinka: lozinka,

      mjestoR: mjestoRodjenja,

      mjestoS: mjestoStanovanja,

      prezime: prezime,

      roditelj: imeRoditelja,

      sifra: sifra,

      telefon: telefon,

      datumL: datumLovackog,

      androidStatus: "ne",

      androidDozvola: "da",
    });
}

export async function zadnjiBroj() {
  var snapshot = await firebase.database().ref("/sifra/").once("value");

  return snapshot;
}
export async function uvecajBroj(brojj) {
  firebase
    .database()
    .ref("/sifra/")
    .update({
      broj: brojj,
    })
    .catch(function (error) {
      alert(error);
    });
}

export async function citaj2() {
  var snapshot = await firebase.database().ref("/clan/").once("value");

  return snapshot;
}
export async function promjeneStatusa(uid) {
  var snapshot = await firebase.database().ref("/promjenaStatusa/" + uid + "/").once("value");

  return snapshot;
}
export async function uplateSifre() {
  var snapshot = await firebase.database().ref("/uplateSifre/").once("value");

  return snapshot;
}

export async function oruzjeSifre() {
  var snapshot = await firebase.database().ref("/oruzjeSifre/").once("value");

  return snapshot;
}

export async function psiSifre() {
  var snapshot = await firebase.database().ref("/psiSifre/").once("value");

  return snapshot;
}
export async function divljacSifre() {
  var snapshot = await firebase.database().ref("/divljac/").once("value");

  return snapshot;
}
export async function uplateLovci() {
  var snapshot = await firebase.database().ref("/uplateLovci/").once("value");

  return snapshot;
}

export async function revir() {
  var snapshot = await firebase.database().ref("/revir/").once("value");

  return snapshot;
}

export async function sekcija() {
  var snapshot = await firebase.database().ref("/sekcija/").once("value");

  return snapshot;
}

export async function funkcija() {
  var snapshot = await firebase.database().ref("/funkcija/").once("value");

  return snapshot;
}

export async function invaliditet() {
  var snapshot = await firebase.database().ref("/invaliditet/").once("value");

  return snapshot;
}

export async function nacija() {
  var snapshot = await firebase.database().ref("/nacija/").once("value");

  return snapshot;
}

export async function sprema() {
  var snapshot = await firebase.database().ref("/sprema/").once("value");

  return snapshot;
}

export async function status() {
  var snapshot = await firebase.database().ref("/status/").once("value");

  return snapshot;
}

export async function zanimanje() {
  var snapshot = await firebase.database().ref("/zanimanje/").once("value");

  return snapshot;
}

export async function dokumenti() {
  var snapshot = await firebase.database().ref("/dokumenti/").once("value");

  return snapshot;
}

export async function upisiUplatu(
  uid,
  vrstaUplate,
  datumUplate,
  lovnaGodina,
  iznosUplate,
  statusUplate,
  brojStampanja
) {
  firebase
    .database()
    .ref("/uplateLovci/" + uid)
    .push({
      datumUplate: datumUplate,
      iznosUplate: iznosUplate,
      lovnaGodina: lovnaGodina,
      vrstaUplate: vrstaUplate,
      statusUplate: statusUplate,
      brojStampanja: brojStampanja,
    });
}

export async function upisiUlov(
  uid,
  vrstaUlova,
  brojLovneKarte,
  datumUlova,
  lovnaGodina,
  tezinaUlova,
  polUlova,
  lokacijaRevir,
  lokacijaSekcija,
  lokacijaOpis,
  kolicinalova,
  nalazUlova,
  statusUlova
) {
  firebase
    .database()
    .ref("/ulovLovci/" + uid)
    .push({
      vrstaUlova: vrstaUlova,
      brojLovneKarte: brojLovneKarte,
      datumUlova: datumUlova,
      lovnaGodina: lovnaGodina,
      tezinaUlova: tezinaUlova,
      polUlova: polUlova,
      lokacijaRevir: lokacijaRevir,
      lokacijaSekcija: lokacijaSekcija,
      lokacijaOpis: lokacijaOpis,
      kolicinaUlova: kolicinalova,
      nalazUlova: nalazUlova,
      statusUlova: statusUlova,
    });
}

export async function upisiOruzje(
  uid,
  vrstaOruzja,
  markaOruzja,
  kalibarOruzja,
  fabrickiBroj,
  oruzaniList,
  brojRegistra,
  optickoPomagalo,
) {
  firebase
    .database()
    .ref("/oruzje/" + uid)
    .push({
      vrstaOruzja: vrstaOruzja,
      markaOruzja: markaOruzja,
      kalibarOruzja: kalibarOruzja,
      fabrickiBroj: fabrickiBroj,
      oruzaniList: oruzaniList,
      brojRegistra: brojRegistra,
      optickoPomagalo: optickoPomagalo,
    });
}

export async function upisiPsa(
  uid,
  vrstaPsa,
  imePsa,
  brojRodovnika,
  datumStenjenja,
  polPsa,
  ocjenaPsa,
  polozenIspit,
  vrstaIspita,
  slikaPsa,
) {
  firebase
    .database()
    .ref("/psi/" + uid)
    .push({
      vrstaPsa: vrstaPsa,
      imePsa: imePsa,
      brojRodovnika: brojRodovnika,
      datumStenjenja: datumStenjenja,
      polPsa: polPsa,
      ocjenaPsa: ocjenaPsa,
      polozenIspit: polozenIspit,
      vrstaIspita: vrstaIspita,
      slikaPsa: slikaPsa,
    });
}

export async function upisiDokument(uid, naziv, slika) {
  firebase
    .database()
    .ref("/dokumenti/" + uid)
    .push({
      naziv: naziv,
      slika: slika,
    });
}

export async function upisiNoviStatus(
  uid,
  noviStatus,
  razlogPromjene,
  datumPromjene
) {
  firebase
    .database()
    .ref("/promjenaStatusa/" + uid)
    .push({
      noviStatus: noviStatus,
      razlogPromjene: razlogPromjene,
      datumPromjene: datumPromjene,
    });
}



export async function obrisiUplatu(uid, kljucUplate, razlog) {
  firebase
    .database()
    .ref("/uplateLovci/" + uid + "/" + kljucUplate)
    .update({
      statusUplate: "obrisan",
      razlogBrisanja: razlog,
    });
}

export async function obrisiOruzje(uid, kljucOruzja) {
  firebase
    .database()
    .ref("/oruzje/" + uid + "/" + kljucOruzja)
    .remove();
}

export async function obrisiPsa(uid, kljucPsa) {
  firebase
    .database()
    .ref("/psi/" + uid + "/" + kljucPsa)
    .remove();
}

export async function obrisiUlov(uid, kljucUplate, razlog) {
  firebase
    .database()
    .ref("/ulovLovci/" + uid + "/" + kljucUplate)
    .update({
      statusUlova: "obrisan",
      razlogBrisanja: razlog,
    });
}

export async function obrisiDokument(uid, kljucDokumenta) {
  firebase
    .database()
    .ref("/dokumenti/" + uid + "/" + kljucDokumenta)
    .remove();
}

export async function ulovLovci() {
  var snapshot = await firebase.database().ref("/ulovLovci/").once("value");

  return snapshot;
}

export async function oruzjeLovci() {
  var snapshot = await firebase.database().ref("/oruzje/").once("value");

  return snapshot;
}

export async function psiLovci() {
  var snapshot = await firebase.database().ref("/psi/").once("value");

  return snapshot;
}

export async function zavrsiUplatu(uid, kljucUplate) {
  firebase
    .database()
    .ref("/uplateLovci/" + uid + "/" + kljucUplate)
    .update({
      statusUplate: "zavrsen",
    });
}

export async function odobriUlov(uid, kljucUplate) {
  firebase
    .database()
    .ref("/ulovLovci/" + uid + "/" + kljucUplate)
    .update({
      statusUlova: "aktivan",
    });
}
export async function dodajBroj(uid, kljucUplate, broj) {
  firebase
    .database()
    .ref("/uplateLovci/" + uid + "/" + kljucUplate)
    .update({
      brojStampanja: broj,
    });
}

export async function izbrisiLovca(uid) {
  firebase
    .database()
    .ref("/clan/" + uid)
    .remove();
}

export async function izbrisiDatum(uid) {
  var prazno = "";
  firebase
    .database()
    .ref("/clan/" + uid + "/")
    .update({
      datumL: prazno,
    });
}

export async function izmjeniLovca(
  status,
  ime,
  prezime,
  sifra,
  funkcija,
  imeRoditelja,
  jmbg,
  email,
  telefon,
  mjestoRodjenja,
  adresa,
  revir,
  sekcija,
  zanimanje,
  firma,
  datumClanstva,
  datumRodjenja,
  invaliditet,
  nacija,
  mjestoStanovanja,
  sprema,
  uid,
  datumLovackog,
  androidDozvola
) {
  firebase
    .database()
    .ref("/clan/" + uid + "/")
    .update({
      adresa: adresa,

      datumC: datumClanstva,

      datumR: datumRodjenja,

      datumL: datumLovackog,

      email: email,

      firma: firma,

      funkcija: funkcija,

      ime: ime,

      jmbg: jmbg,

      lovacInvaliditetK: invaliditet,

      lovacNacijaK: nacija,

      lovacRevirK: revir,

      lovacSekcijaK: sekcija,

      lovacSpremaK: sprema,

      lovacStatusK: status,

      lovacZanimanjeK: zanimanje,

      mjestoR: mjestoRodjenja,

      mjestoS: mjestoStanovanja,

      prezime: prezime,

      roditelj: imeRoditelja,

      sifra: sifra,

      telefon: telefon,

      androidDozvola: androidDozvola,
    });
}

export async function izmjeniSliku(uid) {
  firebase
    .database()
    .ref("/clan/" + uid + "/")
    .update({
      slika: "",
    });
  alert("Izbrisana slika");
}
//export default citaj();
