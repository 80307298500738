import React, { Component } from "react";
import firebase from "../firebase.js";
import fazanlogofull from "../fazanlogofull.png";
import {
  upisiClana,
  citaj2,
  uplateSifre,
  oruzjeSifre,
  psiSifre,
  uplateLovci,
  oruzjeLovci,
  psiLovci,
  upisiUplatu,
  upisiOruzje,
  upisiPsa,
  obrisiUplatu,
  obrisiOruzje,
  obrisiPsa,
  divljacSifre,
  ulovLovci,
  zavrsiUplatu,
  obrisiUlov,
  upisiUlov,
  dodajBroj,
  status,
  sekcija,
  revir,
  zanimanje,
  funkcija,
  invaliditet,
  nacija,
  sprema,
  izmjeniLovca,
  izbrisiLovca,
  odobriUlov,
  dokumenti,
  upisiDokument,
  obrisiDokument,
  izmjeniSliku,
  izbrisiDatum,
  promjeneStatusa,
  upisiNoviStatus,
} from "../funkcije.js";
import "./myStyles.css";
import fazanlogo from "../fazanlogo.png";
import lovaclogo from "../lovaclogo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Input from "react-bootstrap/InputGroup";
import ImageUploader from "react-images-upload";
import Resizer from "react-image-file-resizer";
import "moment/locale/sr";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import ReactToPrint from "react-to-print";
import ReactExport from "react-export-excel";
import "rc-datepicker/lib/style.css";

var ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ExcelRow = ReactExport.ExcelFile.ExcelRow;

class Clanovi extends Component {
  state = {
    baza: [],
    bazaP: [],
    lista: "",
    lovac: "",
    profil: "",
    uplate: "",
    uplateObjekat: [],
    oruzjeObjekat: [],
    psiObjekat: [],
    uplateSifre: [],
    uplateLovci: [],
    oruzjaLovci: [],
    psiLovci: [],
    datumUplate: "",
    datumUlova: "",
    datumClanstva: "",
    datumRodjenja: "",
    datumLovackog: "",
    datumStenjenja: "",
    day: "",
    divljac: [],
    ulovFormaP: "",
    oruzjeP: "",
    dokumentiEkran: "",
    ulovLovci: [],
    status: [],
    sekcija: [],
    revir: [],
    zanimanje: [],
    funkcija: [],
    invaliditet: [],
    nacija: [],
    sprema: [],
    dokumenti: [],
    im: "",
    pictures: [],
    uriDokumenta: "",
  };

  

  styles = {
    border: "1px solid green",
    height: "430px",
    width: "460px",
    background: "#BEE9BF",
    overflow: "scroll",
    float: "left",
    "vertical-align": "middle",
    "text-align": "center",
  };

  styles2 = {
    border: "1px solid green",
    width: "460px",
    height: "40px",
    background: "white",
    "vertical-align": "middle",
    "text-align": "center",
  };

  styles22 = {
    border: "1px solid green",
    width: "227px",
    height: "35px",
    background: "white",
    "vertical-align": "middle",
    "text-align": "center",
  };

  styles3 = {
    border: "1px solid green",
    width: "350px",
    height: "520px",
    overflow: "scroll",
    background: "#BEE9BF",
    "vertical-align": "middle",
    "text-align": "center",
  };
  styles4 = {
    border: "1px solid green",
    width: "460px",
    height: "520px",
    background: "#BEE9BF",
    "vertical-align": "middle",
    "text-align": "center",
  };
  styles5 = {
    border: "1px solid green",
    width: "550px",
    height: "520px",
    background: "#BEE9BF",
    "vertical-align": "middle",
    "text-align": "center",
  };

  styles6 = {
    border: "1px solid green",
    width: "740px",
    height: "520px",
    background: "#BEE9BF",
    "vertical-align": "middle",
    "text-align": "center",
  };

  prikazPsa = {
    color: "#1b8935",
    "vertical-align": "middle",
    "text-align": "left",
    "font-size": "13px",
    "font-weight": "bold",
    "line-height": "0.3",
  };
  stampa = {
    border: "1px solid green",
    width: "550px",
    height: "520px",
    background: "#BEE9BF",
    "vertical-align": "middle",
    "text-align": "center",
  };

  slikaS = {
    width: "200px",
    height: "200px",
    "border-radius": "50%",
    "object-fit": "cover",
  };

  slikaDok = {
    width: "300px",
    height: "400px",
    "object-fit": "scale-down",
  };

  slikaPas = {
    width: "160px",
    height: "160px",
    "object-fit": "scale-down",
  };

  slikaPuska = {
    width: "170px",
    height: "160px",
    "object-fit": "scale-down",
  };

  slikaDok2 = {
    width: "1100px",
    height: "1500px",
    "object-fit": "scale-up",
  };

  danas = () => {
    let da = new Date().toJSON().slice(0, 10).replace(/-/g, "-");

    this.setState({ day: da });
  };

  componentDidMount() {
    this.danas();
    this.uplateLovaca();
    this.divljac();
    this.uloviLovaca();
    this.sifreUplata();
    this.sifreOruzja();
    this.sifrePsi();
    this.status();
    this.sekcija();
    this.revir();
    this.zanimanje();
    this.funkcija();
    this.invaliditet();
    this.nacija();
    this.sprema();
    this.dajBazu();
    this.psiLovaca();
    this.oruzjeLovaca();
    this.dokumenti();

    //this.izbrisiDatum("de9tTGKXyTS5AuIl5ttHvAQ8kuG3");
    //this.izbrisiLovca("G1P2zz5y9rMH3fq4FiRnJMNH93C3");
    //izmjeniSliku("01OVmZZ4Z2cta1qBK7srFUIPd2c2");
    let lol = document.getElementById("ii");
    let filterRS = document.getElementById("filterRS");
    let filterST = document.getElementById("filterST");


    lol.addEventListener("keyup", (ff) => {
      if (lol.value.length > 2 || lol.value == "") {
        let filter=filterRS.value;
        let filter2=filterST.value;
        let slova=lol.value;
        this.pretraga(slova,filter,filter2);
         //alert(filter+slova);
      }
    });
    
    filterRS.addEventListener("change", (ff) => {
     let filter=filterRS.value;
     let filter2=filterST.value;
     let slova=lol.value;
     this.pretraga(slova,filter,filter2);
     //alert(filter+slova);
    });


    filterST.addEventListener("change", (ff) => {
      let filter=filterRS.value;
      let filter2=filterST.value;
      let slova=lol.value;
      this.pretraga(slova,filter,filter2);
      //alert(filter+slova);
     });


  }

  izbrisiLovca = (uid) => {
    izbrisiLovca(uid).then(() => {
      alert("Izbrisan lovac: " + uid);
    });
  };

  izbrisiDatum = (uid) => {
    izbrisiDatum(uid).then(() => {
      alert("Izbrisan datum lovca: " + uid);
    });
  };

  status = () => {
    status().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ status: ff });
    });
  };

  sekcija = () => {
    sekcija().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;
        let revir = ss.val().revir;
        ff.push({
          sifra: sifra,
          naziv: naziv,
          revir: revir,
        });
      });

      this.setState({ sekcija: ff });
    });
  };

  revir = () => {
    revir().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ revir: ff });
    });
  };

  zanimanje = () => {
    zanimanje().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ zanimanje: ff });
    });
  };

  funkcija = () => {
    funkcija().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ funkcija: ff });
    });
  };

  invaliditet = () => {
    invaliditet().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ invaliditet: ff });
    });
  };

  nacija = () => {
    nacija().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ nacija: ff });
    });
  };

  sprema = () => {
    sprema().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ sprema: ff });
    });
  };

  dokumenti = () => {
    dokumenti().then((snapshot) => {
      let dokumenti = [];
      snapshot.forEach((lovac) => {
        var uid = lovac.key;
        lovac.forEach((dokument) => {
          let naziv = dokument.val().naziv;
          let slika = dokument.val().slika;
          let kljuc = dokument.key;

          var dok = {
            uid: uid,
            naziv: naziv,
            slika: slika,
            kljuc: kljuc,
          };
          dokumenti.push(dok);
        });
      });

      this.setState({ dokumenti: dokumenti });
      //alert(this.state.dokumenti[0].naziv);
    });
  };

  sifreUplata = () => {
    uplateSifre().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let cijena = ss.val().cijena;
        let dozvola = ss.val().dozvola;
        ff.push({
          sifra: sifra,
          cijena: cijena,
          dozvola: dozvola,
        });
      });

      this.setState({ uplateObjekat: ff });
    });
  };

  sifreOruzja = () => {
    oruzjeSifre().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let naziv = ss.key;

       
        ff.push({
          naziv: naziv
         
        });
      });

      this.setState({ oruzjeObjekat: ff });
    });
  };

  sifrePsi = () => {
    psiSifre().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let naziv = ss.key;
        ff.push({
          naziv: naziv
         
        });
      });

      this.setState({ psiObjekat: ff });
    });
  };

 

  divljac = () => {
    divljacSifre().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let divljac = ss.key;
        let klasa = ss.val().klasa;

        ff.push({
          divljac: divljac,
          klasa: klasa,
        });
      });

      this.setState({ divljac: ff });
      //alert(this.state.divljac[0].sifra);
    });
  };

  uplateLovaca = () => {
    uplateLovci().then((snapshot) => {
      let uplate = [];
      snapshot.forEach((lovac) => {
        var uid = lovac.key;
        lovac.forEach((uplata) => {
          let vrstaUplate = uplata.val().vrstaUplate;
          let datumUplate = uplata.val().datumUplate;
          let lovnaGodina = uplata.val().lovnaGodina;
          let iznosUplate = uplata.val().iznosUplate;
          let statusUplate = uplata.val().statusUplate;
          let brojStampanja = uplata.val().brojStampanja;
          let kljucUplate = uplata.key;

          var upl = {
            uid: uid,
            vrstaUplate: vrstaUplate,
            datumUplate: datumUplate,
            lovnaGodina: lovnaGodina,
            iznosUplate: iznosUplate,
            statusUplate: statusUplate,
            kljucUplate: kljucUplate,
            brojStampanja: brojStampanja,
          };
          uplate.push(upl);
        });
      });

      this.setState({ uplateLovci: uplate });
      //alert(this.state.uplateLovci[0].jmbg);
    });
  };

  oruzjeLovaca = () => {
    oruzjeLovci().then((snapshot) => {
      let oruzja = [];
      snapshot.forEach((lovac) => {
        var uid = lovac.key;
        lovac.forEach((oruzje) => {
          let vrstaOruzja = oruzje.val().vrstaOruzja;
          let markaOruzja = oruzje.val().markaOruzja;
          let kalibarOruzja = oruzje.val().kalibarOruzja;
          let fabrickiBroj = oruzje.val().fabrickiBroj;
          let oruzaniList = oruzje.val().oruzaniList;
          let brojRegistra = oruzje.val().brojRegistra;
          let optickoPomagalo = oruzje.val().optickoPomagalo;
          let kljucOruzja = oruzje.key;
          let ime="";
          let sifra="";
          let jmbg="";
          let prezime="";
          let revir="";
          let sekcija="";
          this.state.baza.forEach((lo) => {
          if(lo.uid==uid){
            ime=lo.ime;
            prezime=lo.prezime;
            sifra=lo.sifra;
            jmbg=lo.jmbg;
            revir=lo.revirEkran;
            sekcija=lo.sekcijaEkran;
           
          }

          })

          var orz = {
            ime: ime,
            prezime: prezime,
            jmbg: jmbg,
            sifra: sifra,
            revir: revir,
            sekcija: sekcija,
            uid: uid,
            vrstaOruzja: vrstaOruzja,
            markaOruzja: markaOruzja,
            kalibarOruzja: kalibarOruzja,
            fabrickiBroj: fabrickiBroj,
            oruzaniList: oruzaniList,
            brojRegistra: brojRegistra,
            optickoPomagalo: optickoPomagalo,
            kljucOruzja: kljucOruzja,
            
          };
          oruzja.push(orz);
        });
      });

      this.setState({ oruzjaLovci: oruzja });
      //alert(this.state.uplateLovci[0].jmbg);
    });
  };

  psiLovaca = () => {
    psiLovci().then((snapshot) => {
      let psi = [];
      snapshot.forEach((lovac) => {
        var uid = lovac.key;
        lovac.forEach((pas) => {
          let vrstaPsa = pas.val().vrstaPsa;
          let imePsa = pas.val().imePsa;
          let brojRodovnika = pas.val().brojRodovnika;
          let datumStenjenja = pas.val().datumStenjenja;
          let polPsa = pas.val().polPsa;
          let ocjenaPsa = pas.val().ocjenaPsa;
          let polozenIspit = pas.val().polozenIspit;
          let vrstaIspita = pas.val().vrstaIspita;
          let slikaPsa = pas.val().slikaPsa;
          let kljucPsa = pas.key;
          let ime="";
          let sifra="";
          let jmbg="";
          let prezime="";
          let revir="";
          let sekcija="";
          this.state.baza.forEach((lo) => {
          if(lo.uid==uid){
            ime=lo.ime;
            prezime=lo.prezime;
            sifra=lo.sifra;
            jmbg=lo.jmbg;
            revir=lo.revirEkran;
            sekcija=lo.sekcijaEkran;
           
          }

          })

          var ps = {
            ime: ime,
            prezime: prezime,
            jmbg: jmbg,
            sifra: sifra,
            revir: revir,
            sekcija: sekcija,
            uid: uid,
            vrstaPsa: vrstaPsa,
            imePsa: imePsa,
            brojRodovnika: brojRodovnika,
            datumStenjenja: datumStenjenja,
            polPsa: polPsa,
            ocjenaPsa: ocjenaPsa,
            polozenIspit: polozenIspit,
            vrstaIspita: vrstaIspita,
            slikaPsa: slikaPsa,
            kljucPsa: kljucPsa
            
          };
          psi.push(ps);
        });
      });

      this.setState({ psiLovci: psi });
      //alert(this.state.psiLovci[0].ime);
 
    });
  };


  uloviLovaca = () => {
    ulovLovci().then((snapshot) => {
      let ulovi = [];
      snapshot.forEach((lovac) => {
        var uid = lovac.key;
        lovac.forEach((ulov) => {
          let vrstaUlova = ulov.val().vrstaUlova;
          let brojLovneKarte = ulov.val().brojLovneKarte;
          let datumUlova = ulov.val().datumUlova;
          let lovnaGodina = ulov.val().lovnaGodina;
          let tezinaUlova = ulov.val().tezinaUlova;
          let polUlova = ulov.val().polUlova;
          let lokacijaRevir = ulov.val().lokacijaRevir;
          let lokacijaSekcija = ulov.val().lokacijaSekcija;
          let lokacijaOpis = ulov.val().lokacijaOpis;
          let kolicinaUlova = ulov.val().kolicinaUlova;
          let nalazUlova = ulov.val().nalazUlova;
          let statusUlova = ulov.val().statusUlova;
          let kljucUlova = ulov.key;

          var upl = {
            uid: uid,
            vrstaUlova: vrstaUlova,
            brojLovneKarte: brojLovneKarte,
            datumUlova: datumUlova,
            lovnaGodina: lovnaGodina,
            tezinaUlova: tezinaUlova,
            polUlova: polUlova,
            lokacijaRevir: lokacijaRevir,
            lokacijaSekcija: lokacijaSekcija,
            lokacijaOpis: lokacijaOpis,
            kolicinaUlova: kolicinaUlova,
            nalazUlova: nalazUlova,
            statusUlova: statusUlova,
            kljucUlova: kljucUlova,
          };
          ulovi.push(upl);
        });
      });

      this.setState({ ulovLovci: ulovi });
      //alert(this.state.ulovLovci[0].vrstaUlova);
    });
  };

  dajBazu = () => {
    let lol = document.getElementById("ii");
    lol.value = "";
    let bb = [];
    this.setState({ baza: [] });
    this.setState({ bazaP: [] });
    citaj2().then((snapshot) => {
      snapshot.forEach((ss) => {
        let revir = "";
        this.state.revir.map((sss) => {
          if (sss.sifra == ss.val().lovacRevirK) {
            revir = sss.naziv;
            if (sss.sifra == "6" || sss.sifra == "7") {
              revir = "";
            }
          }
        });

        let sekcija = "";
        this.state.sekcija.map((sss) => {
          if (sss.sifra == ss.val().lovacSekcijaK) {
            sekcija = sss.naziv;
          }
        });

    

        let ime = ss.val().ime;
        let prezime = ss.val().prezime;
        let sifra = ss.val().sifra;
        let funkcija = ss.val().funkcija;
        let lozinka = ss.val().lozinka;
        let jmbg = ss.val().jmbg;
        let roditelj = ss.val().roditelj;
        let lovacRevirK = ss.val().lovacRevirK;
        let slika = ss.val().slika;
        let adresa = ss.val().adresa;
        let lovacZanimanjeK = ss.val().lovacZanimanjeK;
        let firma = ss.val().firma;
        let datumC = ss.val().datumC;
        let datumR = ss.val().datumR;
        let lovacInvaliditetK = ss.val().lovacInvaliditetK;
        let lovacNacijaK = ss.val().lovacNacijaK;
        let lovacSekcijaK = ss.val().lovacSekcijaK;
        let mjestoR = ss.val().mjestoR;
        let mjestoS = ss.val().mjestoS;
        let telefon = ss.val().telefon;
        let email = ss.val().email;
        let lovacSpremaK = ss.val().lovacSpremaK;
        let lovacStatusK = ss.val().lovacStatusK;
        let datumL = ss.val().datumL;
        let uid = ss.key;
        let androidStatus = ss.val().androidStatus;
        let androidDozvola = ss.val().androidDozvola;

        bb.push({
          ime: ime,
          prezime: prezime,
          sifra: sifra,
          funkcija: funkcija,
          lozinka: lozinka,
          roditelj: roditelj,
          jmbg: jmbg,
          lovacRevirK: lovacRevirK,
          slika: slika,
          lovacZanimanjeK: lovacZanimanjeK,
          firma: firma,
          datumC: datumC,
          datumR: datumR,
          lovacInvaliditetK: lovacInvaliditetK,
          lovacNacijaK: lovacNacijaK,
          lovacSekcijaK: lovacSekcijaK,
          adresa: adresa,
          mjestoR: mjestoR,
          mjestoS: mjestoS,
          telefon: telefon,
          email: email,
          lovacSpremaK: lovacSpremaK,
          lovacStatusK: lovacStatusK,
          uid: uid,
          revirEkran: revir,
          sekcijaEkran: sekcija,
          datumL: datumL,
          androidStatus: androidStatus,
          androidDozvola: androidDozvola,
        });
      });
      this.setState({ baza: bb });
      this.setState({ bazaP: bb });
      //alert(this.state.bazaP[0].ime);
      this.akcija(this.state.bazaP[0]);
    });
  };
  pretraga = (slova,filter,filter2) => {
    let bbP = [];
    this.setState({ bazaP: [] });
    var frt = 1;
    this.state.baza.forEach(function (ss) {
      frt++;
      try {
        let ime = ss.ime.toString();
        let prezime = ss.prezime.toString();
        let sifra = ss.sifra.toString();
        let funkcija = ss.funkcija;
        let lozinka = ss.lozinka;
        let jmbg = ss.jmbg;
        let roditelj = ss.roditelj;
        let lovacRevirK = ss.lovacRevirK.toString();
        let slika = ss.slika;
        let adresa = ss.adresa;
        let lovacZanimanjeK = ss.lovacZanimanjeK;
        let firma = ss.firma;
        let datumC = ss.datumC;
        let datumR = ss.datumR;
        let lovacInvaliditetK = ss.lovacInvaliditetK;
        let lovacNacijaK = ss.lovacNacijaK;
        let lovacSekcijaK = ss.lovacSekcijaK.toString();
        let mjestoR = ss.mjestoR;
        let mjestoS = ss.mjestoS;
        let telefon = ss.telefon;
        let email = ss.email;
        let lovacSpremaK = ss.lovacSpremaK;
        let lovacStatusK = ss.lovacStatusK;
        let uid = ss.uid;
        let revir = ss.revirEkran;
        let sekcija = ss.sekcijaEkran;
        let datumL = ss.datumL;
        let androidStatus = ss.androidStatus;
        let androidDozvola = ss.androidDozvola;

        if (
          (ime.toLowerCase().includes(slova.toLowerCase()) ||
          prezime.toLowerCase().includes(slova.toLowerCase()) ||
          sifra.toLowerCase().includes(slova.toLowerCase()) ||
          jmbg.toLowerCase().includes(slova.toLowerCase())
          )

          && ( filter == "sve" ||
          filter == (lovacRevirK+"-"+lovacSekcijaK)
          ||
          filter == lovacRevirK)

          && ( filter2 == "sve" ||
          filter2 == lovacStatusK
         )
        )
          bbP.push({
            ime: ime,
            prezime: prezime,
            sifra: sifra,
            funkcija: funkcija,
            lozinka: lozinka,
            roditelj: roditelj,
            jmbg: jmbg,
            lovacRevirK: lovacRevirK,
            slika: slika,
            lovacZanimanjeK: lovacZanimanjeK,
            firma: firma,
            datumC: datumC,
            datumR: datumR,
            lovacInvaliditetK: lovacInvaliditetK,
            lovacNacijaK: lovacNacijaK,
            lovacSekcijaK: lovacSekcijaK,
            adresa: adresa,
            mjestoR: mjestoR,
            mjestoS: mjestoS,
            telefon: telefon,
            email: email,
            lovacSpremaK: lovacSpremaK,
            lovacStatusK: lovacStatusK,
            uid: uid,
            revirEkran: revir,
            sekcijaEkran: sekcija,
            datumL: datumL,
            androidStatus: androidStatus,
            androidDozvola: androidDozvola,
          });
      } catch (er) {
        alert(ss.key);
      }
    });

    this.setState({ bazaP: bbP });
  };

  akcija = (lovac) => {
    this.setState({ uriDokumenta: "" });
    try {
      let status = "";
      this.state.status.map((ss) => {
        if (ss.sifra == lovac.lovacStatusK) {
          status = ss.naziv;
        }
      });

      let revir = "";
      this.state.revir.map((ss) => {
        if (ss.sifra == lovac.lovacRevirK) {
          revir = ss.naziv;
        }
      });

      let sekcija = "";
      this.state.sekcija.map((ss) => {
        if (ss.sifra == lovac.lovacSekcijaK) {
          sekcija = ss.naziv;
        }
      });

      let zanimanje = "";
      this.state.zanimanje.map((ss) => {
        if (ss.sifra == lovac.lovacZanimanjeK) {
          zanimanje = ss.naziv;
        }
      });

      let invaliditet = "";
      this.state.invaliditet.map((ss) => {
        if (ss.sifra == lovac.lovacInvaliditetK) {
          invaliditet = ss.naziv;
        }
      });

      let nacija = "";
      this.state.nacija.map((ss) => {
        if (ss.sifra == lovac.lovacNacijaK) {
          nacija = ss.naziv;
        }
      });

      let sprema = "";
      this.state.sprema.map((ss) => {
        if (ss.sifra == lovac.lovacSpremaK) {
          sprema = ss.naziv;
        }
      });

      let funkcija = "";
      this.state.funkcija.map((ss) => {
        if (ss.sifra == lovac.funkcija) {
          funkcija = ss.naziv;
        }
      });

      let slika = lovac.slika;
      if (lovac.slika == null || lovac.slika == "") {
        slika = lovaclogo;
      }
     
      promjeneStatusa(lovac.uid).then((snapshot) => {
        
        let prom = [];
        snapshot.forEach((ss) => {

          let datumPromjene = ss.val().datumPromjene
          let noviStatus = ss.val().noviStatus
          let razlogPromjene = ss.val().razlogPromjene
            prom.push({
          datumPromjene: datumPromjene,
          noviStatus: noviStatus,
          razlogPromjene: razlogPromjene
        })
         

        })
      
         
      this.setState({ lovac: lovac });
      this.setState({
        profil: (
          <div id="stampaProfil" className="mr-3 mt-3" style={this.styles3}>
            <label class="labelMali2">Status lovca</label>
            {":  "}
            <label class="labelVeliki2">{status}</label>
            <br />
            <img className="m-2" src={slika} style={this.slikaS} />
            <br />
            <label class="labelMali">Ime </label>
            {":  "}
            <label class="labelVeliki">{lovac.ime}</label>
            <br />
            <label class="labelMali">Prezime </label>
            {":  "}
            <label class="labelVeliki">{lovac.prezime}</label>
            <br />
            <label class="labelMali">Šifra </label>
            {":  "}
            <label class="labelVeliki">{lovac.sifra}</label>
            <br />
            <label class="labelMali2">Funkcija </label>
            {":  "}
            <label class="labelVeliki2">{funkcija}</label>
            <br />
            <label class="labelMali2">Ime roditelja </label>
            {":  "}
            <label class="labelVeliki2">{lovac.roditelj}</label>
            <br />
            <label class="labelMali2">Jmbg </label>
            {":  "}
            <label class="labelVeliki2">{lovac.jmbg}</label>
            <hr />
            <label class="labelMali2">Revir</label>
            {":  "}
            <label class="labelVeliki2">{revir}</label>
            <br />
            <label class="labelMali2">Sekcija</label>
            {":  "}
            <label class="labelVeliki2">{sekcija}</label>
            <hr />
            <label class="labelMali2">Email </label>
            {":  "}
            <label class="labelVeliki2">{lovac.email}</label>
            <br />
            <label class="labelMali2">Telefon </label>
            {":  "}
            <label class="labelVeliki2">{lovac.telefon}</label>
            <br />
            <label class="labelMali2">Mjesto rođenja</label>
            {":  "}
            <label class="labelVeliki2">{lovac.mjestoR}</label>
            <br />
            <label class="labelMali2">Adresa stanovanja</label>
            {":  "}
            <label class="labelVeliki2">{lovac.adresa}</label>
            <br />
            <label class="labelMali2">Mjesto stanovanja</label>
            {":  "}
            <label class="labelVeliki2">{lovac.mjestoS}</label>
            <br />
            <label class="labelMali2">Stručna sprema</label>
            {":  "}
            <label class="labelVeliki2">{sprema}</label>
            <br />
            <label class="labelMali2">Zanimanje</label>
            {":  "}
            <label class="labelVeliki2">{zanimanje}</label>
            <br />
            <label class="labelMali2">Firma</label>
            {":  "}
            <label class="labelVeliki2">{lovac.firma}</label>
            <hr />

            <label class="labelMali2">Datum rođenja</label>
            {":  "}
            <label class="labelVeliki2">{lovac.datumR}</label>
            <br />
            <label class="labelMali2">Datum članstva</label>
            {":  "}
            <label class="labelVeliki2">{lovac.datumC}</label>
            <br />
            <label class="labelMali2">Datum lovačkog ispita</label>
            {":  "}
            <label class="labelVeliki2">{lovac.datumL}</label>
            <hr />

            <label class="labelMali2">Invaliditet</label>
            {":  "}
            <label class="labelVeliki2">{invaliditet}</label>
            <br />
            <label class="labelMali2">Nacija</label>
            {":  "}
            <label class="labelVeliki2">{nacija}</label>
            <br />
            <label class="labelMali2">Android status</label>
            {":  "}
            <label class="labelVeliki2">{lovac.androidStatus}</label>
            <br />
            <label class="labelMali2">Android dozvola</label>
            {":  "}
            <label class="labelVeliki2">{lovac.androidDozvola}</label>
            <br />
            <label class="labelMali2">Uid</label>
            {":  "}
            <label class="labelVeliki2">{lovac.uid}</label>

            <br />
            


            <Form.Control
              id="promjenaStatusa"
              as="select"
              onClick={() => this.prikaziPromjenu(lovac)}
              multiple
              className="p-1 m-1 mt-2 mr-2"
              style={{ height: "160px" }}
            >
              {prom.map((ss) => {
             
                  return (
                    <option className="text-success" value={ss.datumPromjene + ":" + ss.noviStatus+ ":" + ss.razlogPromjene}>
                      {ss.datumPromjene}  {ss.noviStatus}  ({ss.razlogPromjene})
                    </option>
                  );
              })}

            </Form.Control>
          
            <Button
              className="btn btn-danger centerButton m-3"
              size="sm"
              onClick={() => this.izmjena(lovac)}
            >
              Izmjeni podatke
            </Button>
            <br />
          </div>
        ),
      });

      this.uplateForma(lovac);
      this.uloviForma(lovac);
      this.psiForma(lovac);
      this.dokumentiForma(lovac);
      this.oruzjeForma(lovac);
      });



    } catch (err) {}
  };

prikaziPromjenu = (lovac) => {
  let v = document.getElementById("promjenaStatusa");
  try{
  let selektovanaprom = v.options[v.selectedIndex].value.split(":");
  let promjena = selektovanaprom[2];
  alert(promjena);}
  catch{}
 }
 

  izmjena = (lovac) => {
    let slika = lovac.slika;
    let RS = lovac.lovacRevirK + "-" + lovac.lovacSekcijaK;

    if (lovac.slika == null || lovac.slika == "") {
      slika = lovaclogo;
    }
    this.setState({ datumRodjenja: this.state.day });
    this.setState({ datumClanstva: this.state.day });
    this.setState({ datumLovackog: this.state.day });
    let dR = this.state.day;
    let dC = this.state.day;
    let dL = this.state.day;

    try {
      if (lovac.datumR == "" || lovac.datumR.includes("undefined")) {
      } else {
        dR =
          lovac.datumR.trim().split(".")[2] +
          "-" +
          lovac.datumR.trim().split(".")[1] +
          "-" +
          lovac.datumR.trim().split(".")[0];
        this.setState({ datumRodjenja: dR });
      }
    } catch {
      alert("greska datum rođenja");
    }

    try {
      if (lovac.datumC == "" || lovac.datumC.includes("undefined")) {
      } else {
        dC =
          lovac.datumC.trim().split(".")[2] +
          "-" +
          lovac.datumC.trim().split(".")[1] +
          "-" +
          lovac.datumC.trim().split(".")[0];
        this.setState({ datumClanstva: dC });
      }
    } catch {
      alert("greska datum clanstva");
    }

    try {
      if (lovac.datumL == "" || lovac.datumL.includes("undefined")) {
      } else {
        dL =
          lovac.datumL.trim().split(".")[2] +
          "-" +
          lovac.datumL.trim().split(".")[1] +
          "-" +
          lovac.datumL.trim().split(".")[0];

        this.setState({ datumLovackog: dL });
      }
    } catch {
      alert("greska datum lovačkog ispita");
    }

    this.setState({ lovac: lovac });
    this.setState({
      profil: (
        <div id="izmjeniProfil" className="mr-3 mt-3" style={this.styles3}>
          <Form onSubmit={this.snimiPromjene}>
            <h6>Status:</h6>
            <Input id="statusI" as="select">
              {this.state.status.map((ss) => {
                if (ss.sifra == lovac.lovacStatusK) {
                  return (
                    <option selected value={ss.sifra}>
                      {ss.naziv}
                    </option>
                  );
                } else {
                  return <option value={ss.sifra}>{ss.naziv}</option>;
                }
              })}
            </Input>

            <img className="m-2" src={slika} style={this.slikaS} />
            <h6>Ime: </h6>
            <Form.Control
              required
              type="text"
              id="imeI"
              placeholder="Ime lovca"
              defaultValue={lovac.ime}
            />
            <h6>Prezime:</h6>
            <Form.Control
              required
              type="text"
              id="prezimeI"
              placeholder="Prezime lovca"
              defaultValue={lovac.prezime}
            />
            <h6>Funkcija:</h6>
            <Input id="funkcijaI" as="select">
              {this.state.funkcija.map((ss) => {
                if (ss.sifra == lovac.funkcija) {
                  return (
                    <option selected value={ss.sifra}>
                      {ss.naziv}
                    </option>
                  );
                } else {
                  return <option value={ss.sifra}>{ss.naziv}</option>;
                }
              })}
            </Input>

            <h6>Jmbg:</h6>
            <Form.Control
              type="number"
              min="0"
              step="1"
              required
              id="jmbgI"
              placeholder="Jmbg lovca"
              defaultValue={lovac.jmbg}
            />
            <h6>Ime Roditelja:</h6>
            <Form.Control
              type="text"
              id="imeRoditeljaI"
              placeholder="Ime roditelja"
              defaultValue={lovac.roditelj}
            />
            <h6>Email:</h6>
            <Form.Control
              type="email"
              id="emailI"
              placeholder="Email lovca"
              defaultValue={lovac.email}
            />
            <h6>Telefon:</h6>
            <Form.Control
              type="text"
              id="telefonI"
              placeholder="Telefon lovca"
              defaultValue={lovac.telefon}
            />
            <h6>Mjesto rođenja:</h6>
            <Form.Control
              type="text"
              id="mjestoRodjenjaI"
              placeholder="Mjesto rođenja lovca"
              defaultValue={lovac.mjestoR}
            />
            <h6>Adresa: </h6>
            <Form.Control
              type="text"
              id="adresaI"
              placeholder="Adresa lovca"
              defaultValue={lovac.adresa}
            />
            <h6> Revir i Sekcija</h6>
            <Input defaultValue={RS} id="revir/sekcija2" as="select">
              <option value="4-1">Ozren-Boljanić</option>{" "}
              <option value="4-16">Ozren-Lipac</option>
              <option value="4-30">Ozren-Tekućica</option>
              <option value="4-23">Ozren-Pridjel</option>
              <option value="4-8">Ozren-RUJ Paklenica</option>
              <option value="4-28">Ozren-SOT Osojnica</option>
              <option value="4-3">Ozren-Brezici</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="1-27">Krnjin-Stanovi</option>
              <option value="1-31">Krnjin-Velika Bukovica</option>
              <option value="1-13">Krnjin-Kladari</option>
              <option value="1-26">Krnjin-Kotorsko</option>
              <option value="1-17">Krnjin-Ljeskove Vode</option>
              <option value="1-11">Krnjin-Grabovica</option>
              <option value="1-24">Krnjin-Putnikovo Brdo</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="3-14">Trebava-Kostajnica</option>
              <option value="3-4">Trebava-Bušletić</option>
              <option value="3-34">Trebava-Sjenina</option>
              <option value="3-6">Trebava-Donja Paležnica</option>
              <option value="3-7">Trebava-Gornja Paležnica</option>
              <option value="3-10">Trebava-Zelinja</option>
              <option value="3-15">Trebava-Kožuhe</option>
              <option value="3-19">Trebava-Osječani</option>
              <option value="3-20">Trebava-Osječanske Čivčije</option>
              <option value="3-36">Trebava-Skipovac</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="5-18">Vučijak-Majevac</option>
              <option value="5-22">Vučijak-Podnovlje</option>
              <option value="5-35">Vučijak-Ritešić</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="6-32">Lovac gost udruženja</option>
              <option value="7-33">Podmladak</option>
            </Input>

            <h6>Zanimanje:</h6>
            <Input id="zanimanjeI" as="select">
              {this.state.zanimanje.map((ss) => {
                if (ss.sifra == lovac.lovacZanimanjeK) {
                  return (
                    <option selected value={ss.sifra}>
                      {ss.naziv}
                    </option>
                  );
                } else {
                  return <option value={ss.sifra}>{ss.naziv}</option>;
                }
              })}
            </Input>

            <h6>Firma: </h6>
            <Form.Control
              type="text"
              id="firmaI"
              placeholder="Firma lovca"
              defaultValue={lovac.firma}
            />
            <h6>Datum članstva:</h6>
            <DatePickerInput
              id="datumClanstvaI"
              defaultValue={dC}
              //locale="es"
              onChange={this.datumClanstva}
            />
            <h6>Datum rođenja:</h6>
            <DatePickerInput
              id="datumRodjenjaI"
              defaultValue={dR}
              //locale="es"
              onChange={this.datumRodjenja}
            />
            <h6>Invaliditet:</h6>
            <Input id="invaliditetI" as="select">
              {this.state.invaliditet.map((ss) => {
                if (ss.sifra == lovac.lovacInvaliditetK) {
                  return (
                    <option selected value={ss.sifra}>
                      {ss.naziv}
                    </option>
                  );
                } else {
                  return <option value={ss.sifra}>{ss.naziv}</option>;
                }
              })}
            </Input>

            <h6>Nacija:</h6>
            <Input id="nacijaI" as="select">
              {this.state.nacija.map((ss) => {
                if (ss.sifra == lovac.lovacNacijaK) {
                  return (
                    <option selected value={ss.sifra}>
                      {ss.naziv}
                    </option>
                  );
                } else {
                  return <option value={ss.sifra}>{ss.naziv}</option>;
                }
              })}
            </Input>

            <h6>Mjesto Stanovanja:</h6>
            <Form.Control
              id="mjestoStanovanjaI"
              type="text"
              placeholder="Mjesto stanovanja lovca"
              defaultValue={lovac.mjestoS}
            />
            <h6>Stručna sprema:</h6>
            <Input id="spremaI" as="select">
              {this.state.sprema.map((ss) => {
                if (ss.sifra == lovac.lovacSpremaK) {
                  return (
                    <option selected value={ss.sifra}>
                      {ss.naziv}
                    </option>
                  );
                } else {
                  return <option value={ss.sifra}>{ss.naziv}</option>;
                }
              })}
            </Input>

            <h6>Android dozvola:</h6>
            <Input
              id="androidDozvolaI"
              as="select"
              defaultValue={lovac.androidDozvola}
            >
              <option value="da">da</option>
              <option value="ne">ne</option>
            </Input>

            <h6>Datum lovačkog ispita:</h6>
            <DatePickerInput
              id="datumLovackogI"
              defaultValue={dL}
              //locale="es"
              onChange={this.datumLovackog}
            />
          
            <Button
              className="m-2 btn btn-success centerButton"
              size="sm"
              onClick={() => this.akcija(lovac)}
            >
              Nazad
            </Button>
            <Button
              className="m-2 btn btn-danger centerButton"
              size="sm"
              type="submit"
            >
              Snimi promjene
            </Button>
          </Form>
        </div>
      ),
    });
  };

  snimiPromjene = (ev) => {
    ev.preventDefault();
    var lovac = this.state.lovac;
    let ime = document.getElementById("imeI").value;

    ime = ime.toLowerCase();
    let ime1 = ime.slice(0, 1);
    let ime2 = ime.slice(1);
    ime = ime1.toUpperCase() + ime2;

    let prezime = document.getElementById("prezimeI").value;

    prezime = prezime.toLowerCase();
    let prezime1 = prezime.slice(0, 1);
    let prezime2 = prezime.slice(1);
    prezime = prezime1.toUpperCase() + prezime2;

    let funkcija = document.getElementById("funkcijaI").options[
      document.getElementById("funkcijaI").selectedIndex
    ].value;
    let imeRoditelja = document.getElementById("imeRoditeljaI").value;

    imeRoditelja = imeRoditelja.toLowerCase();
    let imeRoditelja1 = imeRoditelja.slice(0, 1);
    let imeRoditelja2 = imeRoditelja.slice(1);
    imeRoditelja = imeRoditelja1.toUpperCase() + imeRoditelja2;

    let jmbg = document.getElementById("jmbgI").value;
    let email = document.getElementById("emailI").value;
    let telefon = document.getElementById("telefonI").value;

    let mjestoRodjenja = document.getElementById("mjestoRodjenjaI").value;

    let adresa = document.getElementById("adresaI").value;

    let zanimanje = document.getElementById("zanimanjeI").options[
      document.getElementById("zanimanjeI").selectedIndex
    ].value;
    let firma = document.getElementById("firmaI").value;
    let datumClanstva =
      this.state.datumClanstva.split("-")[2] +
      "." +
      this.state.datumClanstva.split("-")[1] +
      "." +
      this.state.datumClanstva.split("-")[0];

    let datumRodjenja =
      this.state.datumRodjenja.split("-")[2] +
      "." +
      this.state.datumRodjenja.split("-")[1] +
      "." +
      this.state.datumRodjenja.split("-")[0];
    let datumLovackog =
      this.state.datumLovackog.split("-")[2] +
      "." +
      this.state.datumLovackog.split("-")[1] +
      "." +
      this.state.datumLovackog.split("-")[0];
    let invaliditet = document.getElementById("invaliditetI").options[
      document.getElementById("invaliditetI").selectedIndex
    ].value;
    let nacija = document.getElementById("nacijaI").options[
      document.getElementById("nacijaI").selectedIndex
    ].value;
    let mjestoStanovanja = document.getElementById("mjestoStanovanjaI").value;

    let sprema = document.getElementById("spremaI").options[
      document.getElementById("spremaI").selectedIndex
    ].value;
    let status = document.getElementById("statusI").options[
      document.getElementById("statusI").selectedIndex
    ].value;

    let statusText = document.getElementById("statusI").options[
      document.getElementById("statusI").selectedIndex
    ].text;

    let sifra = lovac.sifra;
    let uid = lovac.uid;

    let revir = document
      .getElementById("revir/sekcija2")
      .options[
        document.getElementById("revir/sekcija2").selectedIndex
      ].value.split("-")[0];
    let sekcija = document
      .getElementById("revir/sekcija2")
      .options[
        document.getElementById("revir/sekcija2").selectedIndex
      ].value.split("-")[1];

    var dane = true;
    this.state.baza.forEach((ll) => {
      if (ll.jmbg == jmbg && ll.sifra != sifra) {
        dane = false;
      }
    });

    let androidDozvola = document.getElementById("androidDozvolaI").value;
  
    
    if (jmbg.length == 13 && dane) {
      if (window.confirm("Izmjeni podatke o lovcu?")) {
        izmjeniLovca(
          status,
          ime,
          prezime,
          sifra,
          funkcija,
          imeRoditelja,
          jmbg,
          email,
          telefon,
          mjestoRodjenja,
          adresa,
          revir,
          sekcija,
          zanimanje,
          firma,
          datumClanstva,
          datumRodjenja,
          invaliditet,
          nacija,
          mjestoStanovanja,
          sprema,
          uid,
          datumLovackog,
          androidDozvola
        ).then(() => {
           if(status==lovac.lovacStatusK){
      
           }else{
           var razlogPromjene = window.prompt("Unesite razlog promjene statusa lovca?");
           var datumPromjene=this.state.day.split("-")[2]+"."+this.state.day.split("-")[1]+"."+this.state.day.split("-")[0]
           upisiNoviStatus(uid,statusText,razlogPromjene,datumPromjene);
           }

        }).then(() => {
          let lol = document.getElementById("ii");
          lol.value = "";
          let bb = [];
          this.setState({ baza: [] });
          this.setState({ bazaP: [] });
          citaj2().then((snapshot) => {
            snapshot.forEach((ss) => {
              let revir = "";
              this.state.revir.map((sss) => {
                if (sss.sifra == ss.val().lovacRevirK) {
                  revir = sss.naziv;
                  if (sss.sifra == "6" || sss.sifra == "7") {
                    revir = "";
                  }
                }
              });
              let sekcija = "";
              this.state.sekcija.map((sss) => {
                if (sss.sifra == ss.val().lovacSekcijaK) {
                  sekcija = sss.naziv;
                }
              });
          

              let ime = ss.val().ime;
              let prezime = ss.val().prezime;
              let sifra = ss.val().sifra;
              let funkcija = ss.val().funkcija;
              let lozinka = ss.val().lozinka;
              let jmbg = ss.val().jmbg;
              let roditelj = ss.val().roditelj;
              let lovacRevirK = ss.val().lovacRevirK;
              let slika = ss.val().slika;
              let adresa = ss.val().adresa;
              let lovacZanimanjeK = ss.val().lovacZanimanjeK;
              let firma = ss.val().firma;
              let datumC = ss.val().datumC;
              let datumR = ss.val().datumR;
              let lovacInvaliditetK = ss.val().lovacInvaliditetK;
              let lovacNacijaK = ss.val().lovacNacijaK;
              let lovacSekcijaK = ss.val().lovacSekcijaK;
              let mjestoR = ss.val().mjestoR;
              let mjestoS = ss.val().mjestoS;
              let telefon = ss.val().telefon;
              let email = ss.val().email;
              let lovacSpremaK = ss.val().lovacSpremaK;
              let lovacStatusK = ss.val().lovacStatusK;
              let uid = ss.key;
              let datumL = ss.val().datumL;
              let androidStatus = ss.val().androidStatus;
              let androidDozvola = ss.val().androidDozvola;
              bb.push({
                ime: ime,
                prezime: prezime,
                sifra: sifra,
                funkcija: funkcija,
                lozinka: lozinka,
                roditelj: roditelj,
                jmbg: jmbg,
                lovacRevirK: lovacRevirK,
                slika: slika,
                lovacZanimanjeK: lovacZanimanjeK,
                firma: firma,
                datumC: datumC,
                datumR: datumR,
                lovacInvaliditetK: lovacInvaliditetK,
                lovacNacijaK: lovacNacijaK,
                lovacSekcijaK: lovacSekcijaK,
                adresa: adresa,
                mjestoR: mjestoR,
                mjestoS: mjestoS,
                telefon: telefon,
                email: email,
                lovacSpremaK: lovacSpremaK,
                lovacStatusK: lovacStatusK,
                uid: uid,
                revirEkran: revir,
                sekcijaEkran: sekcija,
                datumL: datumL,
                androidStatus: androidStatus,
                androidDozvola: androidDozvola,
              });
            });
            this.setState({ baza: bb });
            this.setState({ bazaP: bb });

            this.state.bazaP.forEach((novi) => {
              if (novi.sifra == lovac.sifra) {
                lovac = novi;
                alert("Podatci o lovcu su izmjenjeni");
                this.setState({ lovac: lovac });
                this.uplateForma(lovac);
                this.uloviForma(lovac);
                this.psiForma(lovac);
                this.oruzjeForma(lovac);
                this.akcija(lovac);
              }
            });
          });
        });
      }
    } else {
      alert("Neispravan ili dupli jmbg");
    }
  };

  uplate = (lovac) => {
    var uplatePojedinca = [];
    this.state.uplateLovci.forEach((uplata) => {
      if (lovac.uid == uplata.uid && uplata.statusUplate != "obrisan") {
        uplatePojedinca.push(uplata);
      }
    });

    return uplatePojedinca;
  };

  dokumentiLovca = (lovac) => {
    var dokPojedinca = [];
    this.state.dokumenti.forEach((dok) => {
      if (lovac.uid == dok.uid) {
        dokPojedinca.push(dok);
      }
    });

    return dokPojedinca;
  };

  datumUplate = (date) => {
    let ddd =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

    this.setState({ datumUplate: ddd });
  };
  datumUlova = (date) => {
    let ddd =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.setState({ datumUlova: ddd });
  };

  datumStenjenja = (date) => {
    let ddd =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.setState({ datumStenjenja: ddd });
  };

  datumClanstva = (date) => {
    let ddd =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.setState({ datumClanstva: ddd });
  };

  datumRodjenja = (date) => {
    let ddd =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.setState({ datumRodjenja: ddd });
  };

  datumLovackog = (date) => {
    let ddd =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.setState({ datumLovackog: ddd });
  };

  otvori = (lovac) => {
    try {
      let v = document.getElementById("selektovanaUplata");
      let selektovanaUplata = v.options[v.selectedIndex].value.split(";");

      if (window.confirm("Štampaj priznanicu?")) {
        if (selektovanaUplata[6] != 0) {
          if (window.confirm("Priznanica je vec stampana.Štampaj ponovo?")) {
            let broj = Number(selektovanaUplata[6]) + 1;
            dodajBroj(lovac.uid, selektovanaUplata[7], broj)
              .then(() => {
                this.setState({
                  uplateSifre: (
                    <div>
                      <div
                        style={{
                          "font-size": "60px",
                          "text-align": "center",
                          "line-height": "1.2",
                        }}
                        className="d-none d-print-block"
                        ref={(el) => (this.componentRef = el)}
                      >
                        <div
                          style={{
                            "font-size": "50px",
                            "line-height": "1",
                          }}
                        >
                          <label className="mt-4">
                            LOVAČKO UDRUŽENJE ''FAZAN'' DOBOJ
                          </label>
                          <br />
                          <label>Vidovdanska 26F, 74000 Doboj</label>
                          <br />
                          <label>Tel. 053 208 621, Fax 053 208 620</label>
                          <br />
                          <label>web: www.lufazan-doboj.com</label>
                          <br />
                          <label>email: kancelarija@lufazandoboj.com</label>
                          <br />
                          <label>JIB: 4400006740009 -</label>

                          <label> PDV: 400006740009</label>
                        </div>
                        <br />
                        <label
                          className="m-0"
                          style={{
                            "font-size": "90px",
                            "font-weight": "bold",
                          }}
                        >
                          Priznanica
                        </label>
                        <br />

                        <label className="m-0">{selektovanaUplata[7]}</label>
                        <br />

                        <label
                          className="mt-5"
                          style={{
                            "font-size": "80px",
                            "font-weight": "bold",
                          }}
                        >
                          {lovac.ime}
                          {lovac.prezime}
                        </label>
                        <br />
                        <label className="m-0">Šifra: </label>
                        <label className="m-0">{lovac.sifra}</label>
                        <br />
                        <label className="m-0">Vrsta uplate:</label>
                        <br />
                        <label
                          style={{
                            "font-size": "80px",
                            "font-weight": "bold",
                          }}
                        >
                          {selektovanaUplata[2]}
                        </label>
                        <br />
                        <label className="pr-1 m-0">Lovna godina:</label>
                        <label className="m-0">{selektovanaUplata[3]}</label>
                        <br />
                        <label className="pr-1 m-0">Datum uplate:</label>
                        <label className="m-0">{selektovanaUplata[4]}</label>
                        <br />
                        <label className="pr-3">Iznos uplate:</label>
                        <label class="font-weight-bold">
                          {selektovanaUplata[5]},00KM
                        </label>
                        <br />
                        <label>potpis _____________________</label>
                        <br />

                        <label className="mt-5">
                          *priznanica je validna bez pečata
                        </label>
                        <br />
                        <label className="m-0">broj štampanja:{broj}</label>
                      </div>
                      <div className="mt-3" style={this.styles4}>
                        <div
                          style={{
                            "text-align": "center",
                            width: "350px",
                          }}
                        >
                          <div
                            style={{
                              "font-size": "13px",
                              "line-height": "0.5",
                            }}
                          >
                            <label className="mt-4">
                              LOVAČKO UDRUŽENJE ''FAZAN'' DOBOJ
                            </label>
                            <br />
                            <label>Vidovdanska 26F, 74000 Doboj</label>
                            <br />
                            <label>Tel. 053 208 621, Fax 053 208 620</label>
                            <br />
                            <label>web: www.lufazan-doboj.com</label>
                            <br />
                            <label>email: kancelarija@lufazandoboj.com</label>
                            <br />
                            <label>JIB: 4400006740009</label>

                            <label>PDV: 400006740009</label>
                          </div>
                          <h3 className="m-0">Priznanica</h3>
                          <label className="m-0">{selektovanaUplata[7]}</label>
                          <br />
                          <h4>
                            {lovac.ime}
                            {lovac.prezime}
                          </h4>
                          <h5 className="m-0">{lovac.jmbg}</h5>
                          <p className="m-0">Vrsta uplate:</p>
                          <h5>{selektovanaUplata[2]}</h5>
                          <label className="pr-1 m-0">Lovna godina:</label>
                          <label className="m-0">{selektovanaUplata[3]}</label>
                          <br />
                          <label className="pr-1 m-0">Datum uplate:</label>
                          <label className="m-0">{selektovanaUplata[4]}</label>
                          <br />
                          <label className="pr-3">Iznos uplate:</label>
                          <label class="font-weight-bold">
                            {selektovanaUplata[5]},00KM
                          </label>
                          <br />
                          <label>potpis _____________________</label>
                          <br />
                          <label className="m-0">
                            *priznanica je validna bez pečata
                          </label>
                          <br />
                          <label className="m-0">broj štampanja:{broj}</label>
                        </div>

                        <div
                          style={{
                            "text-align": "center",
                            width: "350px",
                          }}
                        >
                          <Button
                            className="m-2 btn btn-success centerButton"
                            onClick={() => this.uplateForma(lovac)}
                            size="sm"
                          >
                            Nazad
                          </Button>

                          <ReactToPrint
                            trigger={() => (
                              <Button
                                size="sm"
                                className="m-2 btn btn-success centerButton"
                                href="#"
                              >
                                Štampaj
                              </Button>
                            )}
                            content={() => this.componentRef}
                          />
                        </div>
                      </div>
                    </div>
                  ),
                });
              })
              .then(() => {
                this.uplateLovaca();
              });
          }
        } else {
          let broj = Number(selektovanaUplata[6]) + 1;
          dodajBroj(lovac.uid, selektovanaUplata[7], broj)
            .then(() => {
              this.setState({
                uplateSifre: (
                  <div>
                    <div
                      style={{
                        "font-size": "60px",
                        "text-align": "center",
                        "line-height": "1.2",
                      }}
                      className="d-none d-print-block"
                      ref={(el) => (this.componentRef = el)}
                    >
                      <div
                        style={{
                          "font-size": "50px",
                          "line-height": "1",
                        }}
                      >
                        <label className="mt-4">
                          LOVAČKO UDRUŽENJE ''FAZAN'' DOBOJ
                        </label>
                        <br />
                        <label>Vidovdanska 26F, 74000 Doboj</label>
                        <br />
                        <label>Tel. 053 208 621, Fax 053 208 620</label>
                        <br />
                        <label>web: www.lufazan-doboj.com</label>
                        <br />
                        <label>email: kancelarija@lufazandoboj.com</label>
                        <br />
                        <label>JIB: 4400006740009 -</label>

                        <label> PDV: 400006740009</label>
                      </div>
                      <br />
                      <label
                        className="m-0"
                        style={{
                          "font-size": "90px",
                          "font-weight": "bold",
                        }}
                      >
                        Priznanica
                      </label>
                      <br />

                      <label className="m-0">{selektovanaUplata[7]}</label>
                      <br />

                      <label
                        className="mt-5"
                        style={{
                          "font-size": "80px",
                          "font-weight": "bold",
                        }}
                      >
                        {lovac.ime}
                        {lovac.prezime}
                      </label>
                      <br />
                      <label className="m-0">Šifra: </label>
                      <label className="m-0">{lovac.sifra}</label>
                      <br />
                      <label className="m-0">Vrsta uplate:</label>
                      <br />
                      <label
                        style={{
                          "font-size": "80px",
                          "font-weight": "bold",
                        }}
                      >
                        {selektovanaUplata[2]}
                      </label>
                      <br />
                      <label className="pr-1 m-0">Lovna godina:</label>
                      <label className="m-0">{selektovanaUplata[3]}</label>
                      <br />
                      <label className="pr-1 m-0">Datum uplate:</label>
                      <label className="m-0">{selektovanaUplata[4]}</label>
                      <br />
                      <label className="pr-3">Iznos uplate:</label>
                      <label class="font-weight-bold">
                        {selektovanaUplata[5]},00KM
                      </label>
                      <br />
                      <label>potpis _____________________</label>
                      <br />

                      <label className="mt-5">
                        *priznanica je validna bez pečata
                      </label>
                      <br />
                      <label className="m-0">broj štampanja:{broj}</label>
                    </div>
                    <div className="mt-3" style={this.styles4}>
                      <div
                        style={{
                          "text-align": "center",
                          width: "350px",
                        }}
                      >
                        <div
                          style={{
                            "font-size": "13px",
                            "line-height": "0.5",
                          }}
                        >
                          <label className="mt-4">
                            LOVAČKO UDRUŽENJE ''FAZAN'' DOBOJ
                          </label>
                          <br />
                          <label>Vidovdanska 26F, 74000 Doboj</label>
                          <br />
                          <label>Tel. 053 208 621, Fax 053 208 620</label>
                          <br />
                          <label>web: www.lufazan-doboj.com</label>
                          <br />
                          <label>email: kancelarija@lufazandoboj.com</label>
                          <br />
                          <label>JIB: 4400006740009</label>

                          <label>PDV: 400006740009</label>
                        </div>
                        <h3 className="m-0">Priznanica</h3>
                        <label className="m-0">{selektovanaUplata[7]}</label>
                        <br />
                        <h4>
                          {lovac.ime}
                          {lovac.prezime}
                        </h4>
                        <h5 className="m-0">{lovac.jmbg}</h5>
                        <p className="m-0">Vrsta uplate:</p>
                        <h5>{selektovanaUplata[2]}</h5>
                        <label className="pr-1 m-0">Lovna godina:</label>
                        <label className="m-0">{selektovanaUplata[3]}</label>
                        <br />
                        <label className="pr-1 m-0">Datum uplate:</label>
                        <label className="m-0">{selektovanaUplata[4]}</label>
                        <br />
                        <label className="pr-3">Iznos uplate:</label>
                        <label class="font-weight-bold">
                          {selektovanaUplata[5]},00KM
                        </label>
                        <br />
                        <label>potpis _____________________</label>
                        <br />
                        <label className="m-0">
                          *priznanica je validna bez pečata
                        </label>
                        <br />
                        <label className="m-0">broj štampanja:{broj}</label>
                      </div>

                      <div
                        style={{
                          "text-align": "center",
                          width: "350px",
                        }}
                      >
                        <Button
                          className="m-2 btn btn-success centerButton"
                          onClick={() => this.uplateForma(lovac)}
                          size="sm"
                        >
                          Nazad
                        </Button>

                        <ReactToPrint
                          trigger={() => (
                            <Button
                              size="sm"
                              className="m-2 btn btn-success centerButton"
                              href="#"
                            >
                              Štampaj
                            </Button>
                          )}
                          content={() => this.componentRef}
                        />
                      </div>
                    </div>
                  </div>
                ),
              });
            })
            .then(() => {
              this.uplateLovaca();
            });
        }
      }
    } catch {
      alert("Molimo selektujte uplatu");
    }
  };

  uplateForma = (lovac) => {
    var uplate = this.uplate(lovac);

    this.setState({
      uplateSifre: (
        <div className=" mt-3  pl-2 pr-2 pb-2 pt-1" style={this.styles4}>
          <Form onSubmit={this.handleSubmit} className="text-center mt-1">
            <Form.Control
              id="selektovanaUplata"
              as="select"
              multiple
              style={{ height: "160px" }}
            >
              {uplate.map((d) => {
                if (d.statusUplate == "aktivan") {
                  return (
                    <option
                      className="text-danger"
                      value={
                        d.uid +
                        ";" +
                        d.kljucUplate +
                        ";" +
                        d.vrstaUplate +
                        ";" +
                        d.lovnaGodina +
                        ";" +
                        d.datumUplate +
                        ";" +
                        d.iznosUplate +
                        ";" +
                        d.brojStampanja +
                        ";" +
                        d.kljucUplate
                      }
                    >
                      {d.datumUplate} {d.vrstaUplate} {d.lovnaGodina}{" "}
                      {d.iznosUplate},00KM
                    </option>
                  );
                } else {
                  return (
                    <option
                      className="text-success"
                      value={
                        d.uid +
                        ";" +
                        d.kljucUplate +
                        ";" +
                        d.vrstaUplate +
                        ";" +
                        d.lovnaGodina +
                        ";" +
                        d.datumUplate +
                        ";" +
                        d.iznosUplate +
                        ";" +
                        d.brojStampanja +
                        ";" +
                        d.kljucUplate
                      }
                      style={{ width: "430px" }}
                    >
                      {d.datumUplate} {d.vrstaUplate} {d.lovnaGodina}{" "}
                      {d.iznosUplate},00KM
                    </option>
                  );
                }
              })}
            </Form.Control>
            <Form.Label className="mt-4">Vrsta uplate</Form.Label>
            <Form.Control id="vrstaUplate" as="select">
              {this.state.uplateObjekat.map((ss) => {
                if (
                  (ss.dozvola == "gost" || ss.dozvola == "svi") &&
                  (lovac.lovacStatusK == "13" || lovac.lovacStatusK == "4")
                ) {
                  return (
                    <option value={ss.sifra + ";" + ss.cijena}>
                      {ss.sifra} {ss.cijena},00KM
                    </option>
                  );
                }
                if (
                  (ss.dozvola == "clan" || ss.dozvola == "svi") &&
                  lovac.lovacStatusK != "13" &&
                  lovac.lovacStatusK != "4"
                ) {
                  return (
                    <option value={ss.sifra + ";" + ss.cijena}>
                      {ss.sifra} {ss.cijena},00KM
                    </option>
                  );
                }
              })}
            </Form.Control>
            <Form.Label className="mt-2">Datum uplate</Form.Label>
            <DatePickerInput
              id="datumUplate"
              defaultValue={this.state.day}
              //locale="es"
              onChange={this.datumUplate}
            />

            <Form.Label className="mt-2">Lovna godina</Form.Label>
            <Form.Control
              id="lovnaGodina"
              as="select"
              defaultValue={"2019/2020"}
            >
              <option value="2018/2019">2018/2019</option>
              <option value="2019/2020">2019/2020</option>
              <option value="2020/2021">2020/2021</option>
              <option value="2021/2022">2021/2022</option>
              <option value="2022/2023">2022/2023</option>
              <option value="2023/2024">2023/2024</option>
              <option value="2024/2025">2024/2025</option>
              <option value="2025/2026">2025/2026</option>
              <option value="2026/2027">2026/2027</option>
              <option value="2027/2028">2027/2028</option>
              <option value="2028/2029">2028/2029</option>
              <option value="2029/2030">2029/2030</option>
            </Form.Control>
            <Button
              id="subUplata"
              className="btn btn-outline-success centerButton"
              type="submit"
              style={{ width: "100px" }}
              className="mt-5"
              size="sm"
              variant="success"
            >
              Upiši uplatu
            </Button>
            <Button
              className="btn btn-outline-warning centerButton"
              onClick={() => this.otvori(lovac)}
              style={{ width: "150px" }}
              className="mt-5 ml-3"
              variant="success"
              size="sm"
            >
              Štampaj priznanicu
            </Button>
            <Button
              id="brisiUplatu"
              className="btn btn-outline-danger  centerButton"
              onClick={this.brisiUplatu}
              style={{ width: "100px" }}
              className="mt-5 ml-3"
              variant="outline-dark"
              size="sm"
            >
              Briši uplatu
            </Button>
            <Button
              className="btn btn-outline-danger  centerButton"
              onClick={this.zavrsiU}
              style={{ width: "30px" }}
              className="mt-5 ml-3"
              variant="outline-danger"
              size="sm"
            >
              Z
            </Button>
          </Form>
        </div>
      ),
    });
  };

  stampajUplatu = () => {
    var printContents = document.getElementById("stampaProfil").innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  };

  brisiUplatu = () => {
    if (window.confirm("Obriši selektovanu uplatu")) {
      let dugme = document.getElementById("brisiUplatu");
      dugme.disabled = true;

      try {
        let v = document.getElementById("selektovanaUplata");
        let selektovanaUplata = v.options[v.selectedIndex].value.split(";");
        let uid = selektovanaUplata[0];
        let kljucUplate = selektovanaUplata[1];

        var razlog = window.prompt("Unesite razlog brisanja?");

        if (razlog != null && razlog.length > 5) {
          obrisiUplatu(uid, kljucUplate, razlog).then(() => {
            uplateLovci().then((snapshot) => {
              let uplate = [];
              snapshot.forEach((lovac) => {
                var uid = lovac.key;
                lovac.forEach((uplata) => {
                  let vrstaUplate = uplata.val().vrstaUplate;
                  let datumUplate = uplata.val().datumUplate;
                  let lovnaGodina = uplata.val().lovnaGodina;
                  let iznosUplate = uplata.val().iznosUplate;
                  let statusUplate = uplata.val().statusUplate;
                  let brojStampanja = uplata.val().brojStampanja;
                  let kljucUplate = uplata.key;
                  var upl = {
                    uid: uid,
                    vrstaUplate: vrstaUplate,
                    datumUplate: datumUplate,
                    lovnaGodina: lovnaGodina,
                    iznosUplate: iznosUplate,
                    statusUplate: statusUplate,
                    kljucUplate: kljucUplate,
                    brojStampanja: brojStampanja,
                  };
                  uplate.push(upl);
                });
              });

              this.setState({ uplateLovci: uplate });
              this.uplateForma(this.state.lovac);
              dugme.disabled = false;
            });
          });
        } else {
          dugme.disabled = false;
          alert("Niste unijeli razlog brisanja");
        }
      } catch (err) {
        dugme.disabled = false;
        alert("Niste selektovali ni jednu uplatu");
      }
    } else {
    }
  };

  brisiUlov = () => {
    if (window.confirm("Obriši selektovan ulov")) {
      let dugme = document.getElementById("brisiUlov");
      dugme.disabled = true;

      try {
        let v = document.getElementById("selektovanUlov");
        let selektovanUlov = v.options[v.selectedIndex].value.split(";");
        let uid = selektovanUlov[0];
        let kljucUlova = selektovanUlov[1];
        var razlog = window.prompt("Unesite razlog brisanja?");

        if (razlog != null && razlog.length > 5) {
          obrisiUlov(uid, kljucUlova, razlog).then(() => {
            ulovLovci().then((snapshot) => {
              let ulovi = [];
              snapshot.forEach((lovac) => {
                var uid = lovac.key;
                lovac.forEach((ulov) => {
                  let vrstaUlova = ulov.val().vrstaUlova;
                  let brojLovneKarte = ulov.val().brojLovneKarte;
                  let datumUlova = ulov.val().datumUlova;
                  let lovnaGodina = ulov.val().lovnaGodina;
                  let tezinaUlova = ulov.val().tezinaUlova;
                  let polUlova = ulov.val().polUlova;
                  let lokacijaRevir = ulov.val().lokacijaRevir;
                  let lokacijaSekcija = ulov.val().lokacijaSekcija;
                  let lokacijaOpis = ulov.val().lokacijaOpis;
                  let kolicinaUlova = ulov.val().kolicinaUlova;
                  let nalazUlova = ulov.val().nalazUlova;
                  let statusUlova = ulov.val().statusUlova;
                  let kljucUlova = ulov.key;

                  var upl = {
                    uid: uid,
                    vrstaUlova: vrstaUlova,
                    brojLovneKarte: brojLovneKarte,
                    datumUlova: datumUlova,
                    lovnaGodina: lovnaGodina,
                    tezinaUlova: tezinaUlova,
                    polUlova: polUlova,
                    lokacijaRevir: lokacijaRevir,
                    lokacijaSekcija: lokacijaSekcija,
                    lokacijaOpis: lokacijaOpis,
                    kolicinaUlova: kolicinaUlova,
                    nalazUlova: nalazUlova,
                    statusUlova: statusUlova,
                    kljucUlova: kljucUlova,
                  };
                  ulovi.push(upl);
                });
              });

              this.setState({ ulovLovci: ulovi });
              this.uloviForma(this.state.lovac);
              dugme.disabled = false;
            });
          });
        } else {
          dugme.disabled = false;
          alert("Niste unijeli razlog brisanja");
        }
      } catch (err) {
        dugme.disabled = false;
        alert("Niste selektovali ni jedan ulov");
      }
    } else {
    }
  };

  brisiOruzje = () => {
    if (window.confirm("Obriši selektovano oruzje")) {
      let dugme = document.getElementById("brisiOruzje");
      dugme.disabled = true;

      try {
        let v = document.getElementById("selektovanoOruzje");
        let selektovanoOruzje = v.options[v.selectedIndex].value.split(";");
        let uid = selektovanoOruzje[0];
        let kljucOruzja = selektovanoOruzje[1];
       

        
          obrisiOruzje(uid, kljucOruzja).then(() => {
            oruzjeLovci().then((snapshot) => {
              let oruzja = [];
              snapshot.forEach((lovac) => {
                var uid = lovac.key;
                lovac.forEach((oruzje) => {
                  let vrstaOruzja = oruzje.val().vrstaOruzja;
                  let markaOruzja = oruzje.val().markaOruzja;
                  let kalibarOruzja = oruzje.val().kalibarOruzja;
                  let fabrickiBroj = oruzje.val().fabrickiBroj;
                  let oruzaniList = oruzje.val().oruzaniList;
                  let brojRegistra = oruzje.val().brojRegistra;
                  let optickoPomagalo = oruzje.val().optickoPomagalo;
                  let kljucOruzja = oruzje.key;
                  let ime="";
                  let sifra="";
                  let jmbg="";
                  let prezime="";
                  let revir="";
                  let sekcija="";
                  this.state.baza.forEach((lo) => {
                  if(lo.uid==uid){
                    ime=lo.ime;
                    prezime=lo.prezime;
                    sifra=lo.sifra;
                    jmbg=lo.jmbg;
                    revir=lo.revirEkran;
                    sekcija=lo.sekcijaEkran;
                   
                  }
        
                  })
        
                  var orz = {
                    ime: ime,
                    prezime: prezime,
                    jmbg: jmbg,
                    sifra: sifra,
                    revir: revir,
                    sekcija: sekcija,
                    uid: uid,
                    vrstaOruzja: vrstaOruzja,
                    markaOruzja: markaOruzja,
                    kalibarOruzja: kalibarOruzja,
                    fabrickiBroj: fabrickiBroj,
                    oruzaniList: oruzaniList,
                    brojRegistra: brojRegistra,
                    optickoPomagalo: optickoPomagalo,
                    kljucOruzja: kljucOruzja,
                    
                  };
                  oruzja.push(orz);
                });
              });
        
              this.setState({ oruzjaLovci: oruzja });
              this.oruzjeForma(this.state.lovac);
              dugme.disabled = false;
            });

        
          });
       
      } catch (err) {
        dugme.disabled = false;
        alert("Niste selektovali ni jedan ulov");
      }
    } else {
    }
  };

  brisiPsa = () => {
    if (window.confirm("Obriši selektovanog psa")) {
      let dugme = document.getElementById("brisiPsa");
      dugme.disabled = true;

      try {
        let v = document.getElementById("selektovanPas");
        let selektovanPas = v.options[v.selectedIndex].value.split(";");
        let uid = selektovanPas[0];
        let kljucPsa = selektovanPas[1];
        let slika = selektovanPas[9];
        let naziv = selektovanPas[3];
       
          obrisiPsa(uid, kljucPsa).then(() => {
            if(slika=="" || slika==null){}
            else{
            var ref = firebase.storage().refFromURL(slika);
            ref
              .delete()
              .then(function () {
                alert("Pas " + naziv + " obrisan!");
              })
              .catch(function (error) {
                alert("Greska pri brisanju slike");
              });

            }

            psiLovci().then((snapshot) => {
              let psi = [];
              snapshot.forEach((lovac) => {
                var uid = lovac.key;
                lovac.forEach((pas) => {
                  let vrstaPsa = pas.val().vrstaPsa;
                  let imePsa = pas.val().imePsa;
                  let brojRodovnika = pas.val().brojRodovnika;
                  let datumStenjenja = pas.val().datumStenjenja;
                  let polPsa = pas.val().polPsa;
                  let ocjenaPsa = pas.val().ocjenaPsa;
                  let polozenIspit = pas.val().polozenIspit;
                  let vrstaIspita = pas.val().vrstaIspita;
                  let slikaPsa = pas.val().slikaPsa;
                  let kljucPsa = pas.key;
                  let ime="";
                  let sifra="";
                  let jmbg="";
                  let prezime="";
                  let revir="";
                  let sekcija="";
                  this.state.baza.forEach((lo) => {
                  if(lo.uid==uid){
                    ime=lo.ime;
                    prezime=lo.prezime;
                    sifra=lo.sifra;
                    jmbg=lo.jmbg;
                    revir=lo.revirEkran;
                    sekcija=lo.sekcijaEkran;
                  }
                  })
        
                  var ps = {
                    ime: ime,
                    prezime: prezime,
                    jmbg: jmbg,
                    sifra: sifra,
                    revir: revir,
                    sekcija: sekcija,
                    uid: uid,
                    vrstaPsa: vrstaPsa,
                    imePsa: imePsa,
                    brojRodovnika: brojRodovnika,
                    datumStenjenja: datumStenjenja,
                    polPsa: polPsa,
                    ocjenaPsa: ocjenaPsa,
                    polozenIspit: polozenIspit,
                    vrstaIspita: vrstaIspita,
                    slikaPsa: slikaPsa,
                    kljucPsa: kljucPsa
                    
                  };
                  psi.push(ps);
                });
              });
        
              this.setState({ psiLovci: psi });
              this.psiForma(this.state.lovac);
              dugme.disabled = false;
            })

        
          });
       
      } catch (err) {
        dugme.disabled = false;
        alert("Niste selektovali ni jednog psa");
      }
    } else {
    }
  };

  brisiDokument = () => {
    if (window.confirm("Obriši selektovan dokument")) {
      let dugme = document.getElementById("brisiDokument");
      dugme.disabled = true;

      try {
        let v = document.getElementById("selektovanDokument");
        let selektovanDokument = v.options[v.selectedIndex].value.split(";");
        let uid = selektovanDokument[0];
        let kljucDokumenta = selektovanDokument[1];
        let naziv = selektovanDokument[2];
        let slika = selektovanDokument[3];

        obrisiDokument(uid, kljucDokumenta).then(() => {
          var ref = firebase.storage().refFromURL(slika);
          ref
            .delete()
            .then(function () {
              alert("Dokument " + naziv + " obrisan!");
            })
            .catch(function (error) {
              alert("Greska pri brisanju slike");
            });

          dokumenti().then((snapshot) => {
            let dokumenti = [];
            snapshot.forEach((lovac) => {
              var uid = lovac.key;
              lovac.forEach((dokument) => {
                let naziv = dokument.val().naziv;
                let slika = dokument.val().slika;
                let kljuc = dokument.key;

                var dok = {
                  uid: uid,
                  naziv: naziv,
                  slika: slika,
                  kljuc: kljuc,
                };
                dokumenti.push(dok);
              });
            });

            this.setState({ dokumenti: dokumenti });
            this.dokumentiForma(this.state.lovac);
            dugme.disabled = false;
          });
        });
      } catch (err) {
        dugme.disabled = false;
        alert("Niste selektovali ni jedan dokument");
      }
    } else {
    }
  };

  zavrsiU = () => {
    if (window.confirm("Završi selektovanu uplatu")) {
      try {
        let v = document.getElementById("selektovanaUplata");
        let selektovanaUplata = v.options[v.selectedIndex].value.split(";");
        let uid = selektovanaUplata[0];
        let kljucUplate = selektovanaUplata[1];

        zavrsiUplatu(uid, kljucUplate).then(() => {
          uplateLovci().then((snapshot) => {
            let uplate = [];
            snapshot.forEach((lovac) => {
              var uid = lovac.key;
              lovac.forEach((uplata) => {
                let vrstaUplate = uplata.val().vrstaUplate;
                let datumUplate = uplata.val().datumUplate;
                let lovnaGodina = uplata.val().lovnaGodina;
                let iznosUplate = uplata.val().iznosUplate;
                let statusUplate = uplata.val().statusUplate;
                let brojStampanja = uplata.val().brojStampanja;
                let kljucUplate = uplata.key;
                var upl = {
                  uid: uid,
                  vrstaUplate: vrstaUplate,
                  datumUplate: datumUplate,
                  lovnaGodina: lovnaGodina,
                  iznosUplate: iznosUplate,
                  statusUplate: statusUplate,
                  kljucUplate: kljucUplate,
                  brojStampanja: brojStampanja,
                };
                uplate.push(upl);
              });
            });

            this.setState({ uplateLovci: uplate });
            this.uplateForma(this.state.lovac);
          });
        });
      } catch (err) {}
    } else {
    }
  };

  odobriUlov = () => {
    if (window.confirm("Odobri selektovan ulov")) {
      try {
        let v = document.getElementById("selektovanUlov");
        let selektovanUlov = v.options[v.selectedIndex].value.split(";");
        let uid = selektovanUlov[0];
        let kljucUlova = selektovanUlov[1];

        odobriUlov(uid, kljucUlova).then(() => {
          ulovLovci().then((snapshot) => {
            let ulovi = [];
            snapshot.forEach((lovac) => {
              var uid = lovac.key;
              lovac.forEach((ulov) => {
                let vrstaUlova = ulov.val().vrstaUlova;
                let brojLovneKarte = ulov.val().brojLovneKarte;
                let datumUlova = ulov.val().datumUlova;
                let lovnaGodina = ulov.val().lovnaGodina;
                let tezinaUlova = ulov.val().tezinaUlova;
                let polUlova = ulov.val().polUlova;
                let lokacijaRevir = ulov.val().lokacijaRevir;
                let lokacijaSekcija = ulov.val().lokacijaSekcija;
                let lokacijaOpis = ulov.val().lokacijaOpis;
                let kolicinaUlova = ulov.val().kolicinaUlova;
                let nalazUlova = ulov.val().nalazUlova;
                let statusUlova = ulov.val().statusUlova;
                let kljucUlova = ulov.key;

                var upl = {
                  uid: uid,
                  vrstaUlova: vrstaUlova,
                  brojLovneKarte: brojLovneKarte,
                  datumUlova: datumUlova,
                  lovnaGodina: lovnaGodina,
                  tezinaUlova: tezinaUlova,
                  polUlova: polUlova,
                  lokacijaRevir: lokacijaRevir,
                  lokacijaSekcija: lokacijaSekcija,
                  lokacijaOpis: lokacijaOpis,
                  kolicinaUlova: kolicinaUlova,
                  nalazUlova: nalazUlova,
                  statusUlova: statusUlova,
                  kljucUlova: kljucUlova,
                };
                ulovi.push(upl);
              });
            });

            this.setState({ ulovLovci: ulovi });
            this.uloviForma(this.state.lovac);
          });
        });
      } catch (err) {}
    } else {
    }
  };

  dokumentiForma = (lovac) => {
    var dokumenti = this.dokumentiLovca(lovac);
    let bro = 1;
    this.setState({
      dokumentiEkran: (
        <div className=" mt-3 ml-3  pl-2 pr-2 pb-2 pt-1" style={this.styles6}>
          <Form onSubmit={this.handleSubmit} className="text-center mt-1">
            <Form.Row className="mt-2 ml-5 mr-5">
              <Col sm="6">
                <Form.Control
                  id="selektovanDokument"
                  as="select"
                  multiple
                  style={{ height: "400px", width: "300px" }}
                  className="mr-2"
                >
                  {dokumenti.map((d) => {
                    return (
                      <option
                        className="text-success"
                        onClick={() => this.slikeUbaci(d.slika)}
                        value={
                          d.uid + ";" + d.kljuc + ";" + d.naziv + ";" + d.slika
                        }
                      >
                        {bro++}.{d.naziv}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
              <Col sm="6">
                <img
                  id="sliketina"
                  style={this.slikaDok}
                  className="mr-2 ml-2"
                />
                <div
                  className="d-none d-print-block"
                  ref={(el) => (this.sss = el)}
                >
                  <img id="sliketina2" className="m-2" style={this.slikaDok2} />
                </div>
              </Col>
            </Form.Row>

            <ReactToPrint
              trigger={() => (
                <Button
                  size="sm"
                  className="m-2 btn btn-success centerButton"
                  href="#"
                >
                  Štampaj dokument
                </Button>
              )}
              content={() => this.sss}
            />

            <Button
              id="brisiDokument"
              className="btn btn-outline-danger  centerButton"
              onClick={this.brisiDokument}
              className="m-2"
              variant="outline-dark"
              size="sm"
            >
              Briši dokument
            </Button>

            <br />

            <Form.Row className="mt-2 ml-5 mr-5">
              <Col sm="4">
                <label for="file-upload" class="meme">
                  Izaberi sliku dokumenta
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={this.fileChangedHandler}
                />
              </Col>

              <Col sm="4">
                <Form.Control
                  id="nazivDok"
                  type="text"
                  placeholder="unesite naziv dokumenta"
                  size="sm"
                ></Form.Control>
              </Col>

              <Col sm="4">
                <Button
                  id="dodajDokument"
                  className="btn btn-success"
                  onClick={() => this.dodajDok(this.state.uriDokumenta)}
                  size="sm"
                >
                  Dodaj dokument
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
      ),
    });

    try {
      let dd = dokumenti[0].slika;
      let v = document.getElementById("sliketina");
      v.src = dd;
      let v2 = document.getElementById("sliketina2");
      v2.src = dd;
    } catch (error) {
      let v = document.getElementById("sliketina");
      v.src = "https://firebasestorage.googleapis.com/v0/b/chat-55edd.appspot.com/o/Univerzalno%2FDokumenti%20680x1000.png?alt=media&token=4fd401fa-600e-4158-a4f5-973abe0ec54d";
      let v2 = document.getElementById("sliketina2");
      v2.src = "https://firebasestorage.googleapis.com/v0/b/chat-55edd.appspot.com/o/Univerzalno%2FDokumenti%20680x1000.png?alt=media&token=4fd401fa-600e-4158-a4f5-973abe0ec54d";
    }
  };
  slikeUbaci = (slika) => {
    this.setState({ uriDokumenta: "" });
    let v = document.getElementById("sliketina");
    v.src = slika;
    let v2 = document.getElementById("sliketina2");
    v2.src = slika;
  };

  dodajDok = (uri) => {
    let dugme = document.getElementById("dodajDokument");
    dugme.disabled = true;
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child("Dokumenti");

    let dok = document.getElementById("nazivDok").value;

    if (uri == "" || dok.length < 4) {
      alert("Molim dodjte sliku i naziv dokumenta");
      dugme.disabled = false;
    } else {
      var d = new Date();
      var n = d.getTime();

      var array, binary, i;
      binary = atob(uri.split(",")[1]);
      array = [];
      i = 0;
      while (i < binary.length) {
        array.push(binary.charCodeAt(i));
        i++;
      }
      let file = new Blob([new Uint8Array(array)], {
        type: "image/jpeg",
      });
      let uid = this.state.lovac.uid.toString();
      const uploadTask = ref.child(uid).child(n.toString()).put(file);

      uploadTask.on(
        "state_changed",
        function (snapshot) {},
        function (error) {
          dugme.disabled = false;
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            upisiDokument(uid, dok, downloadURL.toString()).then(() => {
              dokumenti().then((snapshot) => {
                let dokumenti = [];
                snapshot.forEach((lovac) => {
                  var uid = lovac.key;
                  lovac.forEach((dokument) => {
                    let naziv = dokument.val().naziv;
                    let slika = dokument.val().slika;
                    let kljuc = dokument.key;

                    var dok = {
                      uid: uid,
                      naziv: naziv,
                      slika: slika,
                      kljuc: kljuc,
                    };
                    dokumenti.push(dok);
                  });
                });
                alert("Uspješno ste dodali dokument");
                this.setState({ dokumenti: dokumenti });
                this.dokumentiForma(this.state.lovac);
                dugme.disabled = false;
              });
            });
          });
        }
      );
      this.setState({ uriDokumenta: "" });
      document.getElementById("nazivDok").value = "";
    }
  };

  fileChangedHandler = (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      Resizer.imageFileResizer(
        event.target.files[0],
        800,
        1200,
        "JPEG",
        90,
        0,
        (uri) => {
          let v = document.getElementById("sliketina");
          v.src = uri;
          let r = document.getElementById("sliketina2");
          r.src = uri;
          this.setState({ uriDokumenta: uri });
        },
        "base64"
      );
    }
  };

  handleSubmit = (ev) => {
    if (window.confirm("Upiši uplatu")) {
      ev.preventDefault();
      let dugme = document.getElementById("subUplata");
      dugme.disabled = true;

      let v = document.getElementById("vrstaUplate");
      let vrstaUplateIznosUplate = v.options[v.selectedIndex].value.split(";");
      let vrstaUplate = vrstaUplateIznosUplate[0];
      let iznosUplate = vrstaUplateIznosUplate[1];
      let g = document.getElementById("lovnaGodina");
      let lovnaGodina = g.options[g.selectedIndex].value;
      let datumUplate;
      let statusUplate = "zavrsen";
      if (
        vrstaUplate == "Članarina za lov srndaća" ||
        vrstaUplate == "Članarina za lov divlje svinje" ||
        vrstaUplate == "Ulazna taksa za lov divlje svinje" ||
        vrstaUplate == "Ulazna taksa za lov srndaća"
      ) {
        statusUplate = "aktivan";
      }

      if (this.state.datumUplate == "" || this.state.datumUplate == null) {
        let dd1 =
          this.state.day.split("-")[2] +
          "." +
          this.state.day.split("-")[1] +
          "." +
          this.state.day.split("-")[0];
        datumUplate = dd1;
      } else {
        let dd2 =
          this.state.datumUplate.split("-")[2] +
          "." +
          this.state.datumUplate.split("-")[1] +
          "." +
          this.state.datumUplate.split("-")[0];
        datumUplate = dd2;
      }

      var uplate = this.uplate(this.state.lovac);
      var dozvola = true;
      uplate.forEach((uplata) => {
        if (
          uplata.statusUplate == "aktivan" &&
          uplata.vrstaUplate == vrstaUplate
        ) {
          dozvola = false;
        }
        if (
          uplata.vrstaUplate == "Članarina" &&
          uplata.lovnaGodina == lovnaGodina &&
          vrstaUplate == "Članarina"
        ) {
          dozvola = false;
        }
      });

      if (dozvola) {
        upisiUplatu(
          this.state.lovac.uid,
          vrstaUplate,
          datumUplate,
          lovnaGodina,
          iznosUplate,
          statusUplate,
          0
        ).then(() => {
          uplateLovci().then((snapshot) => {
            let uplate = [];
            snapshot.forEach((lovac) => {
              var uid = lovac.key;
              lovac.forEach((uplata) => {
                let vrstaUplate = uplata.val().vrstaUplate;
                let datumUplate = uplata.val().datumUplate;
                let lovnaGodina = uplata.val().lovnaGodina;
                let iznosUplate = uplata.val().iznosUplate;
                let statusUplate = uplata.val().statusUplate;
                let brojStampanja = uplata.val().brojStampanja;
                let kljucUplate = uplata.key;
                var upl = {
                  uid: uid,
                  vrstaUplate: vrstaUplate,
                  datumUplate: datumUplate,
                  lovnaGodina: lovnaGodina,
                  iznosUplate: iznosUplate,
                  statusUplate: statusUplate,
                  kljucUplate: kljucUplate,
                  brojStampanja: brojStampanja,
                };
                uplate.push(upl);
              });
            });

            this.setState({ uplateLovci: uplate });
            this.uplateForma(this.state.lovac);
            dugme.disabled = false;
          });
        });
      } else {
        dugme.disabled = false;
        alert("Uplata vec postoji");
      }
    } else {
      ev.preventDefault();
    }
  };

  handleSubmitUlov = (ev) => {
    if (window.confirm("Upiši ulov")) {
      ev.preventDefault();
      let dugme = document.getElementById("subUlov");
      dugme.disabled = true;

      let v = document.getElementById("vrstaUlova");
      let vrstaUlovaKlasa = v.options[v.selectedIndex].value.split(";");
      let vrstaUlova = vrstaUlovaKlasa[0];
      let klasa = vrstaUlovaKlasa[1];
      let g = document.getElementById("lovnaGodinaU");
      let lovnaGodina = g.options[g.selectedIndex].value;
      let datumUlova;

      if (this.state.datumUlova == "" || this.state.datumUlova == null) {
        let dd1 =
          this.state.day.split("-")[2] +
          "." +
          this.state.day.split("-")[1] +
          "." +
          this.state.day.split("-")[0];
        datumUlova = dd1;
      } else {
        let dd2 =
          this.state.datumUlova.split("-")[2] +
          "." +
          this.state.datumUlova.split("-")[1] +
          "." +
          this.state.datumUlova.split("-")[0];
        datumUlova = dd2;
      }

      let uplate = this.uplate(this.state.lovac);
      let dozvola = false;
      let kljucUplate = "2";
      uplate.forEach((uplata) => {
        if (
          uplata.statusUplate == "aktivan" &&
          (uplata.vrstaUplate == "Članarina za lov srndaća" ||
            uplata.vrstaUplate == "Ulazna taksa za lov srndaća") &&
          (vrstaUlova == "Srndać" || vrstaUlova == "Srna")
        ) {
          dozvola = true;
          kljucUplate = uplata.kljucUplate;
        }

        if (
          uplata.statusUplate == "aktivan" &&
          (uplata.vrstaUplate == "Članarina za lov divlje svinje" ||
            uplata.vrstaUplate == "Ulazna taksa za lov divlje svinje") &&
          (vrstaUlova == "Vepar" ||
            vrstaUlova == "Krmača" ||
            vrstaUlova == "Nazime" ||
            vrstaUlova == "Prase")
        ) {
          dozvola = true;
          kljucUplate = uplata.kljucUplate;
        }
      });

      if (klasa == "2" || dozvola) {
        let blk = document.getElementById("brojlk");
        let brojLovneKarte = blk.value;
        let tu = document.getElementById("tezinaUlova");
        let tezinaUlova = tu.value;
        let pu = document.getElementById("polUlova");
        let polUlova = pu.options[pu.selectedIndex].value;
        let lr = document.getElementById("revir/sekcija");
        let lokacijaRevir = lr.options[lr.selectedIndex].value.split("-")[0];
        let lokacijaSekcija = lr.options[lr.selectedIndex].value.split("-")[1];
        let lokacijaOpis = document.getElementById("lokacijaOpis").value;
        let ku = document.getElementById("kolicinaUlova");
        let kolicinalova = ku.options[ku.selectedIndex].value;
        let nu = document.getElementById("nalazUlova");
        let nalazUlova = nu.options[nu.selectedIndex].value;
        let statusUlova = "aktivan";

        if (
          vrstaUlova == "Vepar" ||
          vrstaUlova == "Srndać" ||
          vrstaUlova == "Fazan"
        ) {
          polUlova = "Muški";
        }
        if (
          vrstaUlova == "Krmača" ||
          vrstaUlova == "Srna" ||
          vrstaUlova == "Fazanska koka"
        ) {
          polUlova = "Ženski";
        }
        if (
          vrstaUlova == "Vepar" ||
          vrstaUlova == "Srndać" ||
          vrstaUlova == "Krmača" ||
          vrstaUlova == "Srna" ||
          vrstaUlova == "Nazime" ||
          vrstaUlova == "Prase"
        ) {
          kolicinalova = "1";
        }

        upisiUlov(
          this.state.lovac.uid,
          vrstaUlova,
          brojLovneKarte,
          datumUlova,
          lovnaGodina,
          tezinaUlova,
          polUlova,
          lokacijaRevir,
          lokacijaSekcija,
          lokacijaOpis,
          kolicinalova,
          nalazUlova,
          statusUlova
        ).then(() => {
          ulovLovci().then((snapshot) => {
            let ulovi = [];
            snapshot.forEach((lovac) => {
              var uid = lovac.key;
              lovac.forEach((ulov) => {
                let vrstaUlova = ulov.val().vrstaUlova;
                let brojLovneKarte = ulov.val().brojLovneKarte;
                let datumUlova = ulov.val().datumUlova;
                let lovnaGodina = ulov.val().lovnaGodina;
                let tezinaUlova = ulov.val().tezinaUlova;
                let polUlova = ulov.val().polUlova;
                let lokacijaRevir = ulov.val().lokacijaRevir;
                let lokacijaSekcija = ulov.val().lokacijaSekcija;
                let lokacijaOpis = ulov.val().lokacijaOpis;
                let kolicinaUlova = ulov.val().kolicinaUlova;
                let nalazUlova = ulov.val().nalazUlova;
                let statusUlova = ulov.val().statusUlova;
                let kljucUlova = ulov.key;

                var upl = {
                  uid: uid,
                  vrstaUlova: vrstaUlova,
                  brojLovneKarte: brojLovneKarte,
                  datumUlova: datumUlova,
                  lovnaGodina: lovnaGodina,
                  tezinaUlova: tezinaUlova,
                  polUlova: polUlova,
                  lokacijaRevir: lokacijaRevir,
                  lokacijaSekcija: lokacijaSekcija,
                  lokacijaOpis: lokacijaOpis,
                  kolicinaUlova: kolicinaUlova,
                  nalazUlova: nalazUlova,
                  statusUlova: statusUlova,
                  kljucUlova: kljucUlova,
                };
                ulovi.push(upl);
              });
            });

            this.setState({ ulovLovci: ulovi });
            this.uloviForma(this.state.lovac);
            dugme.disabled = false;
            if (dozvola) {
              zavrsiUplatu(this.state.lovac.uid, kljucUplate).then(() => {
                uplateLovci().then((snapshot) => {
                  let uplate = [];
                  snapshot.forEach((lovac) => {
                    var uid = lovac.key;
                    lovac.forEach((uplata) => {
                      let vrstaUplate = uplata.val().vrstaUplate;
                      let datumUplate = uplata.val().datumUplate;
                      let lovnaGodina = uplata.val().lovnaGodina;
                      let iznosUplate = uplata.val().iznosUplate;
                      let statusUplate = uplata.val().statusUplate;
                      let brojStampanja = uplata.val().brojStampanja;
                      let kljucUplate = uplata.key;
                      var upl = {
                        uid: uid,
                        vrstaUplate: vrstaUplate,
                        datumUplate: datumUplate,
                        lovnaGodina: lovnaGodina,
                        iznosUplate: iznosUplate,
                        statusUplate: statusUplate,
                        kljucUplate: kljucUplate,
                        brojStampanja: brojStampanja,
                      };
                      uplate.push(upl);
                    });
                  });

                  this.setState({ uplateLovci: uplate });
                  this.uplateForma(this.state.lovac);
                  dugme.disabled = false;
                });
              });
            }
          });
        });
      } else {
        dugme.disabled = false;
        alert("Ne postoji uplata za ulov");
      }
    } else {
      ev.preventDefault();
    }
  };

  handleSubmitOruzje = (ev) => {
    if (window.confirm("Upiši oružje")) {
      ev.preventDefault();
  

      let vo = document.getElementById("vrstaOruzja");
      let vrstaOruzja = vo.options[vo.selectedIndex].value;

      let markaOruzja = document.getElementById("markaOruzja").value;

     
      let kalibarOruzja = document.getElementById("kalibarOruzja").value;


      let fabrickiBroj = document.getElementById("fabrickiBroj").value;


      let oruzaniList = document.getElementById("oruzaniList").value;

      let brojRegistra = document.getElementById("brojRegistra").value;

      let optickoPomagalo = document.getElementById("optickoPomagalo").value;

        upisiOruzje(
          this.state.lovac.uid,
          vrstaOruzja,
          markaOruzja,
          kalibarOruzja,
          fabrickiBroj,
          oruzaniList,
          brojRegistra,
          optickoPomagalo,
        ).then(() => {

          oruzjeLovci().then((snapshot) => {
            let oruzja = [];
            snapshot.forEach((lovac) => {
              var uid = lovac.key;
              lovac.forEach((oruzje) => {
                let vrstaOruzja = oruzje.val().vrstaOruzja;
                let markaOruzja = oruzje.val().markaOruzja;
                let kalibarOruzja = oruzje.val().kalibarOruzja;
                let fabrickiBroj = oruzje.val().fabrickiBroj;
                let oruzaniList = oruzje.val().oruzaniList;
                let brojRegistra = oruzje.val().brojRegistra;
                let optickoPomagalo = oruzje.val().optickoPomagalo;
                let kljucOruzja = oruzje.key;
                let ime="";
                let sifra="";
                let jmbg="";
                let prezime="";
                let revir="";
                let sekcija="";
                this.state.baza.forEach((lo) => {
                if(lo.uid==uid){
                  ime=lo.ime;
                  prezime=lo.prezime;
                  sifra=lo.sifra;
                  jmbg=lo.jmbg;
                  revir=lo.revirEkran;
                  sekcija=lo.sekcijaEkran;
                 
                }
      
                })
      
                var orz = {
                  ime: ime,
                  prezime: prezime,
                  jmbg: jmbg,
                  sifra: sifra,
                  revir: revir,
                  sekcija: sekcija,
                  uid: uid,
                  vrstaOruzja: vrstaOruzja,
                  markaOruzja: markaOruzja,
                  kalibarOruzja: kalibarOruzja,
                  fabrickiBroj: fabrickiBroj,
                  oruzaniList: oruzaniList,
                  brojRegistra: brojRegistra,
                  optickoPomagalo: optickoPomagalo,
                  kljucOruzja: kljucOruzja,
                  
                };
                oruzja.push(orz);
              });
            });
      
            this.setState({ oruzjaLovci: oruzja });
            this.oruzjeForma(this.state.lovac);
            //alert(this.state.uplateLovci[0].jmbg);
          }).then(() => {
        document.getElementById("markaOruzja").value= "";  
        document.getElementById("kalibarOruzja").value= "";      
        document.getElementById("fabrickiBroj").value= "";   
        document.getElementById("oruzaniList").value= "";
        document.getElementById("brojRegistra").value= "";
        document.getElementById("optickoPomagalo").value= "";
    
          alert("Upisano oruzje")

          })

      
        });
    
    } else {
      ev.preventDefault();
    }
  };

  handleSubmitPsi = (ev) => {
    if (window.confirm("Upiši psa")) {
      ev.preventDefault();
  

      let vp = document.getElementById("vrstaPsa");
      let vrstaPsa = vp.options[vp.selectedIndex].value;

      let imePsa = document.getElementById("imePsa").value;

      let brojRodovnika = document.getElementById("brojRodovnika").value;
     
      let pp = document.getElementById("polPsa");
      let polPsa = pp.options[pp.selectedIndex].value;

      let op = document.getElementById("ocjenaPsa");
      let ocjenaPsa = op.options[op.selectedIndex].value;

      let pi = document.getElementById("polozenIspit");
      let polozenIspit = pi.options[pi.selectedIndex].value;

      let vi = document.getElementById("vrstaIspita");
      let vrstaIspita = vi.options[vi.selectedIndex].value;

      let slikaPsa = "";

      let datumStenjenja;

      if (this.state.datumStenjenja == "" || this.state.datumStenjenja == null) {
        let dd1 =
          this.state.day.split("-")[2] +
          "." +
          this.state.day.split("-")[1] +
          "." +
          this.state.day.split("-")[0];
          datumStenjenja = dd1;
      } else {
        let dd2 =
          this.state.datumStenjenja.split("-")[2] +
          "." +
          this.state.datumStenjenja.split("-")[1] +
          "." +
          this.state.datumStenjenja.split("-")[0];
          datumStenjenja = dd2;
      }

        upisiPsa(
          this.state.lovac.uid,
          vrstaPsa,
          imePsa,
          brojRodovnika,
          datumStenjenja,
          polPsa,
          ocjenaPsa,
          polozenIspit,
          vrstaIspita,
          slikaPsa
        ).then(() => {

          psiLovci().then((snapshot) => {
            let psi = [];
            snapshot.forEach((lovac) => {
              var uid = lovac.key;
              lovac.forEach((pas) => {
                let vrstaPsa = pas.val().vrstaPsa;
                let imePsa = pas.val().imePsa;
                let brojRodovnika = pas.val().brojRodovnika;
                let datumStenjenja = pas.val().datumStenjenja;
                let polPsa = pas.val().polPsa;
                let ocjenaPsa = pas.val().ocjenaPsa;
                let polozenIspit = pas.val().polozenIspit;
                let vrstaIspita = pas.val().vrstaIspita;
                let slikaPsa = pas.val().slikaPsa;
                let kljucPsa = pas.key;
                let ime="";
                let sifra="";
                let jmbg="";
                let prezime="";
                let revir="";
                let sekcija="";
                this.state.baza.forEach((lo) => {
                if(lo.uid==uid){
                  ime=lo.ime;
                  prezime=lo.prezime;
                  sifra=lo.sifra;
                  jmbg=lo.jmbg;
                  revir=lo.revirEkran;
                  sekcija=lo.sekcijaEkran;
                }
                })
      
                var ps = {
                  ime: ime,
                  prezime: prezime,
                  jmbg: jmbg,
                  sifra: sifra,
                  revir: revir,
                  sekcija: sekcija,
                  uid: uid,
                  vrstaPsa: vrstaPsa,
                  imePsa: imePsa,
                  brojRodovnika: brojRodovnika,
                  datumStenjenja: datumStenjenja,
                  polPsa: polPsa,
                  ocjenaPsa: ocjenaPsa,
                  polozenIspit: polozenIspit,
                  vrstaIspita: vrstaIspita,
                  slikaPsa: slikaPsa,
                  kljucPsa: kljucPsa
                  
                };
                psi.push(ps);
              });
            });
      
            this.setState({ psiLovci: psi });
            this.psiForma(this.state.lovac);
          })

         .then(() => {
        document.getElementById("imePsa").value= "";  
        document.getElementById("brojRodovnika").value= "";  
    
          alert("Upisan pas")

          })

      
        });
    
    } else {
      ev.preventDefault();
    }
  };

  handleDate = (ev) => {
    ev.preventDefault();
    this.setState({ datum: ev.target.value });
  };

  ulovi = (lovac) => {
    var uloviPojedinca = [];
    this.state.ulovLovci.forEach((ulov) => {
      if (lovac.uid == ulov.uid && ulov.statusUlova != "obrisan") {
        uloviPojedinca.push(ulov);
      }
    });

    return uloviPojedinca;
  };

  oruzja = (lovac) => {
    var oruzjaPojedinca = [];
    this.state.oruzjaLovci.forEach((oruz) => {
      if (lovac.uid == oruz.uid) {
        oruzjaPojedinca.push(oruz);
      }
    });

    return oruzjaPojedinca;
  };

  pas = (lovac) => {
    var pasPojedinca = [];
    this.state.psiLovci.forEach((ps) => {
      if (lovac.uid == ps.uid) {
        pasPojedinca.push(ps);
      }
    });

    return pasPojedinca;
  };

  uloviForma = (lovac) => {
    var ulovi = this.ulovi(lovac);

    this.setState({
      ulovFormaP: (
        <div
          className="ml-3 mt-3 pl-2 pr-2 pb-2 pt-1"
          style={this.styles5}
        >
          <Form onSubmit={this.handleSubmitUlov} className="text-center mt-1">
            <Form.Control
              id="selektovanUlov"
              as="select"
              multiple
              style={{ height: "160px" }}
            >
              {ulovi.map((d) => {
                if (d.statusUlova == "aktivan") {
                  return (
                    <option
                      className="text-success"
                      value={
                        d.uid +
                        ";" +
                        d.kljucUlova +
                        ";" +
                        d.vrstaUlova +
                        ";" +
                        d.datumUlova +
                        ";" +
                        d.kolicinaUlova +
                        ";" +
                        d.lokacijaRevir +
                        ";" +
                        d.lokacijaSekcija +
                        ";" +
                        d.lovnaGodina +
                        ";" +
                        d.nalazUlova +
                        ";" +
                        d.polUlova +
                        ";" +
                        d.tezinaUlova +
                        ";" +
                        d.brojLovneKarte +
                        ";" +
                        d.lokacijaOpis
                      }
                    >
                      {d.datumUlova} {d.vrstaUlova} {d.lovnaGodina}
                    </option>
                  );
                } else {
                  return (
                    <option
                      className="text-primary"
                      value={
                        d.uid +
                        ";" +
                        d.kljucUlova +
                        ";" +
                        d.vrstaUlova +
                        ";" +
                        d.datumUlova +
                        ";" +
                        d.kolicinaUlova +
                        ";" +
                        d.lokacijaRevir +
                        ";" +
                        d.lokacijaSekcija +
                        ";" +
                        d.lovnaGodina +
                        ";" +
                        d.nalazUlova +
                        ";" +
                        d.polUlova +
                        ";" +
                        d.tezinaUlova +
                        ";" +
                        d.brojLovneKarte +
                        ";" +
                        d.lokacijaOpis
                      }
                    >
                      {d.datumUlova} {d.vrstaUlova} {d.lovnaGodina}
                    </option>
                  );
                }
              })}
            </Form.Control>

            <Form.Row className="mt-3">
              <Col sm="2">
                <Form.Label>Vrsta ulova</Form.Label>
              </Col>
              <Col sm="4">
                <Form.Control id="vrstaUlova" as="select">
                  {this.state.divljac.map((ss) => {
                    return (
                      <option value={ss.divljac + ";" + ss.klasa}>
                        {ss.divljac}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
              <Col sm="2">
                <Form.Label>Broj LK</Form.Label>
              </Col>
              <Col sm="4">
                <Form.Control id="brojlk" type="text"></Form.Control>
              </Col>
            </Form.Row>

            <Form.Row className="mt-2">
              <Col sm="2">
                <Form.Label>Datum</Form.Label>
              </Col>
              <Col sm="4">
                <DatePickerInput
                  id="datumUlova"
                  defaultValue={this.state.day}
                  //locale="es"
                  onChange={this.datumUlova}
                />
              </Col>

              <Col sm="3">
                <Form.Label>Lovna godina</Form.Label>
              </Col>
              <Col sm="3">
                <Form.Control
                  id="lovnaGodinaU"
                  as="select"
                  defaultValue={"2019/2020"}
                >
                  <option value="2018/2019">2018/2019</option>
                  <option value="2019/2020">2019/2020</option>
                  <option value="2020/2021">2020/2021</option>
                  <option value="2021/2022">2021/2022</option>
                  <option value="2022/2023">2022/2023</option>
                  <option value="2023/2024">2023/2024</option>
                  <option value="2024/2025">2024/2025</option>
                  <option value="2025/2026">2025/2026</option>
                  <option value="2026/2027">2026/2027</option>
                  <option value="2027/2028">2027/2028</option>
                  <option value="2028/2029">2028/2029</option>
                  <option value="2029/2030">2029/2030</option>
                </Form.Control>
              </Col>
            </Form.Row>

            <Form.Row className="mt-2">
              <Col sm="2">
                <Form.Label>Lokacija</Form.Label>
              </Col>
              <Col sm="10">
                <Form.Control id="revir/sekcija" as="select">
                  <option value="Ozren-Boljanić">Ozren-Boljanić</option>{" "}
                  <option value="Ozren-Lipac">Ozren-Lipac</option>
                  <option value="Ozren-Tekućica">Ozren-Tekućica</option>
                  <option value="Ozren-Pridjel">Ozren-Pridjel</option>
                  <option value="Ozren-RUJ Paklenica">
                    Ozren-RUJ Paklenica
                  </option>
                  <option value="Ozren-SOT Osojnica">Ozren-SOT Osojnica</option>
                  <option value="Ozren-Brezici">Ozren-Brezici</option>
                  <option value="" disabled>
                    . . . . . . . . . . . . .
                  </option>
                  <option value="Krnjin-Stanovi">Krnjin-Stanovi</option>
                  <option value="Krnjin-Velika Bukovica">
                    Krnjin-Velika Bukovica
                  </option>
                  <option value="Krnjin-Kladari">Krnjin-Kladari</option>
                  <option value="Krnjin-Kotorsko">Krnjin-Kotorsko</option>
                  <option value="Krnjin-Ljeskove Vode">
                    Krnjin-Ljeskove Vode
                  </option>
                  <option value="Krnjin-Grabovica">Krnjin-Grabovica</option>
                  <option value="Krnjin-Putnikovo Brdo">
                    Krnjin-Putnikovo Brdo
                  </option>
                  <option value="" disabled>
                    . . . . . . . . . . . . .
                  </option>
                  <option value="Trebava-Kostajnica">Trebava-Kostajnica</option>
                  <option value="Trebava-Bušletić">Trebava-Bušletić</option>
                  <option value="Trebava-Sjenina">Trebava-Sjenina</option>
                  <option value="Trebava-Donja Paležnica">
                    Trebava-Donja Paležnica
                  </option>
                  <option value="Trebava-Gornaj Paležnica">
                    Trebava-Gornaj Paležnica
                  </option>
                  <option value="Trebava-Zelinja">Trebava-Zelinja</option>
                  <option value="Trebava-Kožuhe">Trebava-Kožuhe</option>
                  <option value="Trebava-Osječani">Trebava-Osječani</option>
                  <option value="Trebava-Osječanske Čivčije">
                    Trebava-Osječanske Čivčije
                  </option>
                  <option value="Trebava-Skipovac">Trebava-Skipovac</option>
                  
                  <option value="" disabled>
                    . . . . . . . . . . . . .
                  </option>
                  <option value="Vučijak-Majevac">Vučijak-Majevac</option>
                  <option value="Vučijak-Podnovlje">Vučijak-Podnovlje</option>
                  <option value="Vučijak-Ritešić">Vučijak-Ritešić</option>
                </Form.Control>
              </Col>
            </Form.Row>

            <Form.Row className="mt-2">
              <Col sm="2">
                <Form.Label>Lokacija</Form.Label>
              </Col>
              <Col sm="10">
                <Form.Control
                  id="lokacijaOpis"
                  type="text"
                  placeholder="opis mjesta ulova"
                ></Form.Control>
              </Col>
            </Form.Row>

            <Form.Row className="mt-2">
              <Col sm="3">
                <Form.Label>Količina ulova</Form.Label>
              </Col>
              <Col sm="3">
                <Form.Control id="kolicinaUlova" as="select">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </Form.Control>
              </Col>

              <Col sm="3">
                <Form.Label>Pol ulova</Form.Label>
              </Col>
              <Col sm="3">
                <Form.Control id="polUlova" as="select">
                  <option value="Muški">Muški</option>
                  <option value="Ženski">Ženski</option>
                  <option value="Neodređen">Neodređen</option>
                </Form.Control>
              </Col>
            </Form.Row>

            <Form.Row className="mt-2">
              <Col sm="3">
                <Form.Label>Težina ulova Kg</Form.Label>
              </Col>
              <Col sm="3">
                <Form.Control
                  id="tezinaUlova"
                  type="number"
                  min="0.01"
                  step="0.01"
                  required
                ></Form.Control>
              </Col>

              <Col sm="3">
                <Form.Label>Nalaz</Form.Label>
              </Col>
              <Col sm="3">
                <Form.Control id="nalazUlova" as="select">
                  <option value="Negativan">Negativan</option>
                  <option value="Pozitivan">Pozitivan</option>
                </Form.Control>
              </Col>
            </Form.Row>

            <Button
              id="subUlov"
              className="btn btn-primary centerButton"
              type="submit"
              style={{ width: "100px" }}
              className="mt-3"
              size="sm"
              variant="success"
            >
              Upiši ulov
            </Button>

            <Button
              id="subUlov"
              className="btn btn-primary centerButton"
              onClick={() => this.prikaziUlov(lovac)}
              style={{ width: "100px" }}
              className="mt-3 ml-3"
              size="sm"
              variant="success"
            >
              Prikaži ulov
            </Button>

            <Button
              id="brisiUlov"
              className="btn btn-danger  centerButton"
              onClick={this.brisiUlov}
              style={{ width: "100px" }}
              className="mt-3 ml-3"
              variant="outline-dark"
              size="sm"
            >
              Briši ulov
            </Button>
            <Button
              id="odobriUlov"
              className="btn btn-danger  centerButton"
              onClick={this.odobriUlov}
              style={{ width: "100px" }}
              className="mt-3 ml-3"
              variant="outline-dark"
              size="sm"
            >
              Odobri ulov
            </Button>
          </Form>
        </div>
      ),
    });
  };

  psiForma = (lovac) => {
   

    var pas = this.pas(lovac);
    let b=1;
    this.setState({
      psiP: (
        <div
          className="ml-3 mt-3 pl-2 pr-2 pb-2 pt-1"
          style={this.styles5}
        >
          <Form onSubmit={this.handleSubmitPsi} className="text-center mt-1">
            <Form.Control
              style={{ height: "100px", overflow: "scroll" }}
              id="selektovanPas"
              as="select"
              multiple
            
            >
             
              {pas.map((d) => {
                  return (
                    <option
                      className="text-success"
                      onClick={() => this.slikeUbaciPsa(d)}
                      value={
                        d.uid +
                        ";" +
                        d.kljucPsa +
                        ";" +
                        d.vrstaPsa +
                        ";" +
                        d.imePsa +
                        ";" +
                        d.datumStenjenja +
                        ";" +
                        d.polPsa +
                        ";" +
                        d.ocjenaPsa +
                        ";" +
                        d.polozenIspit +
                        ";" +
                        d.vrstaIspita +
                        ";" +
                        d.slikaPsa
                    
                      }
                    >
                      {b++}.{d.vrstaPsa} | ime-{d.imePsa} 
                    </option>
                  );
              
              })}
            </Form.Control>

            <div style={this.prikazPsa}>
            <Form.Row >
              <Col sm="8" className="mt-2 pl-3" >
                <p id="vrstaPsaP" ></p>
                <p id="imePsaP" ></p>
                <p id="datumStenjenjaP" ></p>
                <Form.Row >
                <Col sm="5">
                <p id="polPsaP" ></p>
                </Col>
                <Col sm="7">
                <p id="ocjenaPsaP" ></p>
                </Col>
                </Form.Row>
                <Form.Row >
                <Col sm="5">
                <p id="polozenIspitP" ></p>
                </Col>
                <Col sm="7">
                <p id="vrstaIspitaP" ></p>
                </Col>
                </Form.Row>
                <p id="brojRodovnikaP" ></p>
              </Col>
              <Col sm="4">
                  <img
                  id="slikaPsa"
                  style={this.slikaPas}
                  className="mt-1"
                />
              </Col>
          
               </Form.Row>
               </div>

            <Form.Row className="mt-3">
              <Col sm="3">
                <Form.Label>Vrsta psa</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control id="vrstaPsa" as="select">
                  {this.state.psiObjekat.map((ss) => {
                    return (
                      <option value={ss.naziv}>
                        {ss.naziv}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
             
            </Form.Row>

            <Form.Row className="mt-2">

            <Col sm="4">
                <Form.Control id="imePsa" required
                  type="text"
                  placeholder="upišite ime psa"></Form.Control>
              </Col>
              <Col sm="4">
                <Form.Control id="brojRodovnika" required
                  type="text"
                  placeholder="upišite broj rodovnika"></Form.Control>
              </Col>
              
              <Col sm="4">
                <DatePickerInput
                  id="datumStenjenja"
                  defaultValue={this.state.day}
                  //locale="es"
                  onChange={this.datumStenjenja}
                />
              </Col>

            </Form.Row>


            <Form.Row className="mt-2">
              <Col sm="3">
                <Form.Label>Pol psa</Form.Label>
              </Col>
              <Col sm="3">
                <Form.Control id="polPsa" as="select">
                <option value="Muški">Muški</option>
                  <option value="Ženski">Ženski</option>
                </Form.Control>
              </Col>

              <Col sm="3">
                <Form.Label>Ocjena</Form.Label>
              </Col>
              <Col sm="3">
                <Form.Control id="ocjenaPsa" as="select">
                <option value="5">5</option>
                <option value="4">4</option>
                <option value="3">3</option>
                <option value="2">2</option>
                <option value="Nije ocjenjen">Nije ocjenjen</option>
                 
                </Form.Control>
              </Col>
            </Form.Row>

            <Form.Row className="mt-2">
              <Col sm="3">
                <Form.Label>Položen ispit</Form.Label>
              </Col>
              <Col sm="3">
                <Form.Control id="polozenIspit" as="select">
                  <option value="Da">Da</option>
                  <option value="Ne">Ne</option>
                 
                </Form.Control>
              </Col>

              <Col sm="3">
                <Form.Label>Vrsta ispita</Form.Label>
              </Col>
              <Col sm="3">
                <Form.Control id="vrstaIspita" as="select">
                  <option value="IPO">IPO</option>
                  <option value="IPO Divlja svinja">IPO Divlja svinja</option>
                  <option value="IPO zec">IPO zec</option>
                  <option value="IPO lisica">IPO lisica</option>
                  
                </Form.Control>
              </Col>
            </Form.Row>

           
            <Button
              id="subPas"
              className="btn btn-primary centerButton"
              type="submit"
              style={{ width: "100px" }}
              className="mt-3"
              size="sm"
              variant="success"
            >
              Upiši psa
            </Button>

           
            <Button
              id="brisiPsa"
              className="btn btn-danger  centerButton"
              onClick={this.brisiPsa}
              style={{ width: "100px" }}
              className="mt-3 ml-3"
              variant="outline-dark"
              size="sm"
            >
              Briši psa
            </Button>

            <ExcelFile
            filename={
             "Svi Psi"
            }
            element={
              <Button
              className="btn btn-primary centerButton"
              type="submit"
              style={{ width: "200px" }}
              className="mt-3 ml-3"
              size="sm"
              variant="success"
               
              >
                Snimi tabelu svih pasa
              </Button>
            }
          >
            <ExcelSheet
              data={this.state.psiLovci}
              name={"Psi"}
            >
              <ExcelColumn label="Ime lovca" value="ime" />
              <ExcelColumn label="Prezime lovca" value="prezime" />
              <ExcelColumn label="Šifra lovca" value="sifra" />
              <ExcelColumn label="Jmbg lovca" value="jmbg" />
              <ExcelColumn label="Revir lovca" value="revir" />
              <ExcelColumn label="Sekcija lovca" value="sekcija" />
              <ExcelColumn label="vrstaPsa" value="vrstaPsa" />
              <ExcelColumn label="imePsa" value="imePsa" />
              <ExcelColumn label="brojRodovnika" value="brojRodovnika" />
              <ExcelColumn label="datumStenjenja" value="datumStenjenja" />
              <ExcelColumn label="polPsa" value="polPsa" />
              <ExcelColumn label="ocjenaPsa" value="ocjenaPsa" />
              <ExcelColumn label="polozenIspit" value="polozenIspit" />
              <ExcelColumn label="vrstaIspita" value="vrstaIspita" />
   
            </ExcelSheet>

          </ExcelFile>
          </Form>



        </div>
      ),
    });

     
  
      let v = document.getElementById("slikaPsa");
    try{
      v.src = "https://firebasestorage.googleapis.com/v0/b/chat-55edd.appspot.com/o/PsiSlike%2FlovackiPas.png?alt=media&token=6e07ca20-9bc6-47a8-9361-568e2edd5844";
  }catch(err){
    
  }
    let rr2 = document.getElementById("vrstaPsaP"); 
    rr2.innerText =("")
    let rr3 = document.getElementById("imePsaP"); 
    rr3.innerText =("")
    let rr4 = document.getElementById("datumStenjenjaP"); 
    rr4.innerText =("")
    let rr5 = document.getElementById("polPsaP"); 
    rr5.innerText =("")
    let rr6 = document.getElementById("ocjenaPsaP"); 
    rr6.innerText =("")
    let rr7 = document.getElementById("polozenIspitP"); 
    rr7.innerText =("")
    let rr8 = document.getElementById("vrstaIspitaP"); 
    rr8.innerText =("")
    let rr9 = document.getElementById("brojRodovnikaP"); 
    rr9.innerText =("")

  };

  slikeUbaciPsa = (d) => {
    var slika=d.slikaPsa;
    if(slika=="" || slika==null){
      let v = document.getElementById("slikaPsa");
      v.src = "https://firebasestorage.googleapis.com/v0/b/chat-55edd.appspot.com/o/PsiSlike%2FlovackiPas.png?alt=media&token=6e07ca20-9bc6-47a8-9361-568e2edd5844";

    }else{
    let v = document.getElementById("slikaPsa");
    v.src = slika;
    }

   let rr2 = document.getElementById("vrstaPsaP"); 
   rr2.innerText =(" vrsta psa: "+d.vrstaPsa)
   let rr3 = document.getElementById("imePsaP"); 
   rr3.innerText =(" ime psa: "+d.imePsa)
   let rr4 = document.getElementById("datumStenjenjaP"); 
   rr4.innerText =(" datum stenjenja: "+d.datumStenjenja)
   let rr5 = document.getElementById("polPsaP"); 
   rr5.innerText =(" pol psa: "+d.polPsa)
   let rr6 = document.getElementById("ocjenaPsaP"); 
   rr6.innerText =(" ocjena psa: "+d.ocjenaPsa)
   let rr7 = document.getElementById("polozenIspitP"); 
   rr7.innerText =(" položen ispit: "+d.polozenIspit)
   let rr8 = document.getElementById("vrstaIspitaP"); 
   rr8.innerText =(" ispit: "+d.vrstaIspita)
   let rr9 = document.getElementById("brojRodovnikaP"); 
   rr9.innerText =(" rodovnik: "+d.brojRodovnika)

  };

  ubaciPodatkeO = (d) => {
  
   
   let rr2 = document.getElementById("vrstaOruzjaP"); 
   rr2.innerText =("vrsta oružja: "+d.vrstaOruzja)
   let rr3 = document.getElementById("markaOruzjaP"); 
   rr3.innerText =("marka oružja: "+d.markaOruzja)
   let rr4 = document.getElementById("kalibarOruzjaP"); 
   rr4.innerText =("kalibar: "+d.kalibarOruzja)
   let rr5 = document.getElementById("oruzaniListP"); 
   rr5.innerText =("oružani list: "+d.oruzaniList)
   let rr6 = document.getElementById("fabrickiBrojP"); 
   rr6.innerText =("fabrički broj: "+d.fabrickiBroj)
   let rr7 = document.getElementById("brojRegistraP"); 
   rr7.innerText =("broj registra: "+d.brojRegistra)
   let rr8 = document.getElementById("optickoPomagaloP"); 
   rr8.innerText =("optičko pomagalo: "+d.optickoPomagalo)
  
   

  };

  oruzjeForma = (lovac) => {

    var oruzje = this.oruzja(lovac);
    let i = 1;
    this.setState({
      oruzjeP: (
        <div
          className="ml-3 mt-3 pl-2 pr-2 pb-2 pt-1"
          style={this.styles5}
        >
          <Form onSubmit={this.handleSubmitOruzje} className="text-center mt-1">
            <Form.Control
              id="selektovanoOruzje"
              as="select"
              multiple
              style={{ height: "100px", overflow: "scroll" }}
            >
              {oruzje.map((d) => {
               
                  return (
                    <option
                      className="text-success"
                      onClick={() => this.ubaciPodatkeO(d)}
                      value={
                        d.uid +
                        ";" +
                        d.kljucOruzja +
                        ";" +
                        d.vrstaOruzja +
                        ";" +
                        d.markaOruzja +
                        ";" +
                        d.kalibarOruzja +
                        ";" +
                        d.fabrickiBroj +
                        ";" +
                        d.oruzaniList 
                      }
                    >
                      {i++} {d.vrstaOruzja} | marka-{d.markaOruzja} | kalibar-{d.kalibarOruzja}
                    </option>
                  );
                
              })}
            </Form.Control>
            <div style={this.prikazPsa}>
            <Form.Row >
              <Col sm="8" className="mt-2 pl-3" >
                <p id="vrstaOruzjaP" ></p>
                <p id="markaOruzjaP" ></p>
                <p id="kalibarOruzjaP" ></p>            
                <p id="fabrickiBrojP" ></p>            
                <p id="oruzaniListP" ></p>   
                <p id="brojRegistraP" ></p> 
                <p id="optickoPomagaloP" ></p>                                    
              </Col>
              <Col sm="4">
              <img
                  id="slikaPuska"
                  style={this.slikaPuska}
                  className="mt-1"
                />
              </Col>
          
               </Form.Row>
               </div>
           

            <Form.Row className="mt-2">
              <Col sm="4">
                <Form.Label>Vrsta oružja</Form.Label>
              </Col>
              <Col sm="8">
                <Form.Control id="vrstaOruzja" as="select">
                {this.state.oruzjeObjekat.map((ss) => {
                    return (
                      <option value={ss.naziv}>
                        {ss.naziv}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
            </Form.Row>

            <Form.Row className="mt-2">
             
              <Col sm="6">
                <Form.Control
                  id="markaOruzja"
                  required
                  type="text"
                  placeholder="upiši marku oružja"
                ></Form.Control>
              </Col>
                       
              <Col sm="6">
                <Form.Control
                  id="kalibarOruzja"
                  required
                  type="text"
                  placeholder="upisi kalibar oruzja"
                ></Form.Control>
              </Col>
            </Form.Row>
            <Form.Row className="mt-2">

<Col sm="6"> 
  <Form.Control
    id="optickoPomagalo"
    type="text"
    placeholder="upiši optičko pomagalo"
  ></Form.Control>
</Col>


<Col sm="6">
  <Form.Control
    id="brojRegistra"
    required
    type="text"
    placeholder="upiši broj registra"
  ></Form.Control>

</Col>
</Form.Row>

       <Form.Row className="mt-2">

              <Col sm="6"> 
                <Form.Control
                  id="fabrickiBroj"
                  required
                  type="text"
                  placeholder="upiši fabrički broj oružja"
                ></Form.Control>
              </Col>
            

              <Col sm="6">
                <Form.Control
                  id="oruzaniList"
                  required
                  type="text"
                  placeholder="upiši broj oružanog lista"
                ></Form.Control>
           
            </Col>
       </Form.Row>



            <Button
              id="subUlov"
              className="btn btn-primary centerButton"
              type="submit"
              style={{ width: "100px" }}
              className="mt-3"
              size="sm"
              variant="success"
            >
              Upiši oruzje
            </Button>

            <Button
              id="brisiOruzje"
              className="btn btn-danger  centerButton"
              onClick={this.brisiOruzje}
              style={{ width: "100px" }}
              className="mt-3 ml-3"
              variant="outline-dark"
              size="sm"
            >
              Briši oruzje
            </Button>

            <ExcelFile
            filename={
             "Sva oružja"
            }
            element={
              <Button
              className="btn btn-primary centerButton"
              type="submit"
              style={{ width: "200px" }}
              className="mt-3 ml-3"
              size="sm"
              variant="success"
               
              >
                Snimi tabelu svih oružja
              </Button>
            }
          >
            <ExcelSheet
              data={this.state.oruzjaLovci}
              name={"Oružja"}
            >
              <ExcelColumn label="Ime lovca" value="ime" />
              <ExcelColumn label="Prezime lovca" value="prezime" />
              <ExcelColumn label="Šifra lovca" value="sifra" />
              <ExcelColumn label="Jmbg lovca" value="jmbg" />
              <ExcelColumn label="Revir lovca" value="revir" />
              <ExcelColumn label="Sekcija lovca" value="sekcija" />
              <ExcelColumn label="Vrsta oružja" value="vrstaOružja" />
              <ExcelColumn label="Marka" value="markaOruzja" />
              <ExcelColumn label="Kalibar" value="kalibarOruzja" />
              <ExcelColumn label="Fabrički broj" value="fabrickiBroj" />
              <ExcelColumn label="Oružani list" value="oruzaniList" />
              <ExcelColumn label="Broj registra" value="brojRegistra" />
              <ExcelColumn label="Opticko pomagalo" value="optickoPomagalo" />
              
          
            </ExcelSheet>

          </ExcelFile>
           </Form>


        </div>
      ),
    });

    let v = document.getElementById("slikaPuska");
    v.src = "https://firebasestorage.googleapis.com/v0/b/chat-55edd.appspot.com/o/Univerzalno%2Flovacka.png?alt=media&token=58b7715f-e7ae-4b4f-b553-137403deb6ef";  


    let rr2 = document.getElementById("vrstaOruzjaP"); 
    rr2.innerText =("")
    let rr3 = document.getElementById("markaOruzjaP"); 
    rr3.innerText =("")
    let rr4 = document.getElementById("kalibarOruzjaP"); 
    rr4.innerText =("")
    let rr5 = document.getElementById("fabrickiBrojP"); 
    rr5.innerText =("")
    let rr6 = document.getElementById("oruzaniListP"); 
    rr6.innerText =("")
    let rr7 = document.getElementById("brojRegistraP"); 
    rr7.innerText =("")
    let rr8 = document.getElementById("optickoPomagaloP"); 
    rr8.innerText =("")
 

  };


  prikaziUlov = (lovac) => {
    try {
      let v = document.getElementById("selektovanUlov");
      let selektovanUlov = v.options[v.selectedIndex].value.split(";");

      this.setState({
        ulovFormaP: (
          <div
            className="ml-3 mt-3 pl-2 pr-2 pb-2 pt-1"
            style={this.styles5}
          >
            <br />
            <h1>Ulov</h1>
            <br />
            <label class="labelMali">Vrsta ulova </label>
            {":  "}
            <label class="labelVeliki">{selektovanUlov[2]}</label>
            <br />

            <label class="labelMali">Datum ulova </label>
            {":  "}
            <label class="labelVeliki">{selektovanUlov[3]}</label>
            <br />

            <label class="labelMali">Lovna godina </label>
            {":  "}
            <label class="labelVeliki">{selektovanUlov[7]}</label>
            <br />

            <label class="labelMali">Količina ulova </label>
            {":  "}
            <label class="labelVeliki">{selektovanUlov[4]}</label>
            <br />

            <label class="labelMali">Težina ulova </label>
            {":  "}
            <label class="labelVeliki">{selektovanUlov[10]}</label>
            <br />

            <label class="labelMali">Pol ulova </label>
            {":  "}
            <label class="labelVeliki">{selektovanUlov[9]}</label>
            <br />

            <label class="labelMali">Revir ulova </label>
            {":  "}
            <label class="labelVeliki">{selektovanUlov[5]}</label>
            <br />

            <label class="labelMali">Sekija ulova </label>
            {":  "}
            <label class="labelVeliki">{selektovanUlov[6]}</label>
            <br />

            <label class="labelMali">Opis lokacije ulova </label>
            {":  "}
            <label class="labelVeliki">{selektovanUlov[12]}</label>
            <br />

            <label class="labelMali">Nalaz ulova </label>
            {":  "}
            <label class="labelVeliki">{selektovanUlov[8]}</label>
            <br />

            <label class="labelMali">Lovna karta </label>
            {":  "}
            <label class="labelVeliki">{selektovanUlov[11]}</label>
            <br />

            <Button
              id="subUlov"
              className="btn btn-primary centerButton"
              onClick={() => this.uloviForma(this.state.lovac)}
              style={{ width: "100px" }}
              className="mt-3"
              size="sm"
              variant="success"
            >
              Nazad
            </Button>
          </div>
        ),
      });
    } catch {
      alert("Molimo selektujte ulov");
    }
  };

  lista = () => {
    let i = 1;
    this.setState({
      ulovFormaP: (
        <div>
          <div>
            <input
              style={this.styles2}
              className="ml-3 mt-3"
              type="text"
              name="enter"
              id="ii"
              placeholder=" pretraži po imenu, prezimenu i broju dozvole"
            />
          </div>
          <div className="ml-3 mt-2 mr-3" style={this.styles}>
            {this.state.bazaP.map((d) => (
              <div>
                <button
                  className="btn btn-outline-success m-1"
                  style={{
                    width: "430px",
                    color: "#218838",
                    "font-weight": "bold",
                  }}
                  onClick={() => this.akcija(d)}
                  key={d.ime}
                >
                  {i++}. {d.ime} {d.prezime} {d.jmbg}
                </button>
              </div>
            ))}
          </div>
        </div>
      ),
    });
  };

  render() {
    let i = 1;

    return (
      <div>
        <div style={{ float: "left" }}>
          <div>
          <div>
            <input
              style={this.styles2}
              className="ml-3 mt-3"
              type="text"
              name="enter"
              id="ii"
              placeholder=" pretraži po imenu,prezimenu,jmbg,broju dozvole"
            />
            </div>
            <div style={{float: "left"}}>
              <Input id="filterRS" as="select" style={this.styles22}
               className="ml-3 mt-1">
               <option value="sve">SVE</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="4">OZREN</option>
              <option value="4-1">Ozren-Boljanić</option>
              <option value="4-16">Ozren-Lipac</option>
              <option value="4-30">Ozren-Tekućica</option>
              <option value="4-23">Ozren-Pridjel</option>
              <option value="4-8">Ozren-RUJ Paklenica</option>
              <option value="4-28">Ozren-SOT Osojnica</option>
              <option value="4-3">Ozren-Brezici</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="1">KRNJIN</option>
              <option value="1-27">Krnjin-Stanovi</option>
              <option value="1-31">Krnjin-Velika Bukovica</option>
              <option value="1-13">Krnjin-Kladari</option>
              <option value="1-26">Krnjin-Kotorsko</option>
              <option value="1-17">Krnjin-Ljeskove Vode</option>
              <option value="1-11">Krnjin-Grabovica</option>
              <option value="1-24">Krnjin-Putnikovo Brdo</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="3">TREBAVA</option>
              <option value="3-14">Trebava-Kostajnica</option>
              <option value="3-4">Trebava-Bušletić</option>
              <option value="3-34">Trebava-Sjenina</option>
              <option value="3-6">Trebava-Donja Paležnica</option>
              <option value="3-7">Trebava-Gornja Paležnica</option>
              <option value="3-10">Trebava-Zelinja</option>
              <option value="3-15">Trebava-Kožuhe</option>
              <option value="3-19">Trebava-Osječani</option>
              <option value="3-20">Trebava-Osječanske Čivčije</option>
              <option value="3-36">Trebava-Skipovac</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="5">VUČIJAK</option>
              <option value="5-18">Vučijak-Majevac</option>
              <option value="5-22">Vučijak-Podnovlje</option>
              <option value="5-35">Vučijak-Ritešić</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="6-32">Lovac gost udruženja</option>
              <option value="7-33">Podmladak</option>
            </Input>
            </div>


            <div style={{float: "left"}}>
              <Input id="filterST" as="select" style={this.styles22}
               className="ml-1 mt-1">
                 <option value="sve">SVE</option>
                    {this.state.status.map((ss) => {
                  return <option value={ss.sifra}>{ss.naziv}</option>;
                })}
            </Input>
            </div>



          </div>
          <div className="ml-3 mt-2 mr-3" style={this.styles}>
            {this.state.bazaP.map((d) => (
              <div>
                <button
                  className="btn btn-outline-success m-1"
                  style={{
                    width: "430px",
                    color: "#218838",
                    "font-weight": "bold",
                  }}
                  onClick={() => this.akcija(d)}
                  key={d.ime}
                >
                  {i++}. {d.ime} {d.prezime} {d.sifra} {d.revirEkran}-
                  {d.sekcijaEkran}
                </button>
              </div>
            ))}
                        <ExcelFile
            filename={
             "Lovci/Lovac"
            }
            element={
              <Button
              className="btn btn-primary centerButton"
              type="submit"
              style={{ width: "200px" }}
              className="m-3"
              size="sm"
              variant="success"
               
              >
                Snimi tabelu/lovca
              </Button>
            }
          >
            <ExcelSheet
              data={this.state.bazaP}
              name={"Lovac/Lovci"}
            >
              <ExcelColumn label="Ime" value="ime" />
              <ExcelColumn label="Prezime" value="prezime" />
              <ExcelColumn label="Šifra" value="sifra" />
              <ExcelColumn label="JMBG" value="jmbg" />
              <ExcelColumn label="Roditelj" value="roditelj" />
              <ExcelColumn label="Status" value="lovacStatusK" />
              <ExcelColumn label="Revir" value="revirEkran" />
              <ExcelColumn label="Sekcija" value="sekcijaEkran" />
              <ExcelColumn label="Datum rođenja" value="datumR" />
              <ExcelColumn label="Datum članstva" value="datumC" />
              <ExcelColumn label="Mjesto Rođenja" value="mjestoR" />
              <ExcelColumn label="Mjesto stanovanja" value="mjestoS" />
              <ExcelColumn label="Adresa" value="adresa" />
              <ExcelColumn label="Telefon" value="telefon" />
              <ExcelColumn label="Email" value="email" />
              <ExcelColumn label="Firma" value="firma" />
   
            </ExcelSheet>

          </ExcelFile>
          </div>
        </div>

        <div style={{ float: "left" }}>{this.state.profil}</div>
        <div style={{ float: "left" }}>{this.state.uplateSifre}</div>
        <div style={{ float: "left" }}>{this.state.ulovFormaP}</div>
        <div style={{ float: "left" }}>{this.state.dokumentiEkran}</div>
        
        <div style={{ float: "left" }}>{this.state.oruzjeP}</div>
        <div style={{ float: "left" }}>{this.state.psiP}</div>
       
      </div>
    );
  }
}

export default Clanovi;
