import React, { Component } from "react";
import {
  upisiClana,
  status,
  sekcija,
  revir,
  zanimanje,
  funkcija,
  invaliditet,
  nacija,
  sprema,
  zadnjiBroj,
  uvecajBroj,
  dodajKorisnika,
} from "../funkcije.js";
import lovaclogo from "../lovaclogo.png";
import "./myStyles.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Input from "react-bootstrap/InputGroup";
import "moment/locale/sr";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import ReactToPrint from "react-to-print";
import "rc-datepicker/lib/style.css";
class NoviClan extends Component {
  state = {
    forma: "",
    status: [],
    sekcija: [],
    revir: [],
    zanimanje: [],
    funkcija: [],
    invaliditet: [],
    nacija: [],
    sprema: [],
    datumClanstva: "",
    datumRodjenja: "",
    datumLovackog: "",
    zadnjiBroj: "",
    day: "",
  };

  styles1 = {
    border: "1px solid green",
    width: "1000px",
    height: "520px",
    background: "#BEE9BF",
    left: "50%",
    "margin-left": "-500px",
    "margin-top": "10px",
    position: "absolute",

    //left: "1%"
  };

  styles2 = {
    width: "300px",
    height: "500px",
    "margin-top": "10px",
    "margin-left": "20px",
    background: "#BEE9BF",
    "vertical-align": "middle",
    "text-align": "center",
    float: "left",
  };

  styles3 = {
    height: "37px",
  };

  styles4 = {};

  glavni = {
    width: "100%",
    height: "100%",
    background: "#BEE9BF",
    position: "absolute",
    "text-align": "center",
    "vertical-align": "middle",
  };

  slikaS = {
    width: "200px",
    height: "200px",
    "border-radius": "50%",
    "object-fit": "cover",
  };

  componentDidMount() {
    this.status();
    this.danas();
  }

  danas = () => {
    let da = new Date().toJSON().slice(0, 10).replace(/-/g, "-");

    this.setState({ day: da });
    this.setState({ datumClanstva: da });
    this.setState({ datumRodjenja: da });
    this.setState({ datumLovackog: "1900-11-11" });
  };

  status = () => {
    status().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ status: ff });
      this.sekcija();
    });
  };

  sekcija = () => {
    sekcija().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;
        let revir = ss.val().revir;
        ff.push({
          sifra: sifra,
          naziv: naziv,
          revir: revir,
        });
      });

      this.setState({ sekcija: ff });
      this.revir();
    });
  };

  revir = () => {
    revir().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ revir: ff });
      this.zanimanje();
    });
  };

  zanimanje = () => {
    zanimanje().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ zanimanje: ff });
      this.funkcija();
    });
  };

  funkcija = () => {
    funkcija().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ funkcija: ff });
      this.invaliditet();
    });
  };

  invaliditet = () => {
    invaliditet().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ invaliditet: ff });
      this.nacija();
    });
  };

  nacija = () => {
    nacija().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ nacija: ff });
      this.sprema();
    });
  };

  sprema = () => {
    sprema().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ sprema: ff });
      this.zadnjiBroj();
    });
  };

  zadnjiBroj = () => {
    zadnjiBroj().then((snapshot) => {
      let broj = snapshot.val().broj;
      broj = Number(broj) + 1;
      this.setState({ zadnjiBroj: broj });
      this.dajFormu();
    });
  };

  datumClanstva = (date) => {
    let ddd =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.setState({ datumClanstva: ddd });
  };

  datumRodjenja = (date) => {
    let ddd =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.setState({ datumRodjenja: ddd });
  };

  datumLovackog = (date) => {
    let ddd =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.setState({ datumLovackog: ddd });
  };

  dajFormu = () => {
    //let slika = lovaclogo;

    this.setState({
      forma: (
        <div id="izmjeniProfil" className="mr-3 mt-3" style={this.styles1}>
          <Form onSubmit={this.proba}>
            <div style={this.styles2}>
              <h6>STATUS:</h6>
              <Input id="statusI" as="select" style={this.styles3}>
                {this.state.status.map((ss) => {
                  return <option value={ss.sifra}>{ss.naziv}</option>;
                })}
              </Input>

              <h6>IME: </h6>
              <Form.Control
                required
                type="text"
                id="imeI"
                placeholder="Ime lovca"
              />
              <h6>PREZIME:</h6>
              <Form.Control
                required
                type="text"
                id="prezimeI"
                placeholder="Prezime lovca"
              />
              <h6>FUNKCIJA:</h6>
              <Input id="funkcijaI" as="select" style={this.styles3}>
                {this.state.funkcija.map((ss) => {
                  return <option value={ss.sifra}>{ss.naziv}</option>;
                })}
              </Input>

              <h6>JMBG:</h6>
              <Form.Control
                type="number"
                min="0"
                step="1"
                required
                id="jmbgI"
                placeholder="Jmbg lovca"
              />
              <h6>IME RODITELJA:</h6>
              <Form.Control
                type="text"
                id="imeRoditeljaI"
                placeholder="Ime roditelja"
              />
              <h6>EMAIL:</h6>
              <Form.Control
                type="email"
                id="emailI"
                placeholder="Email lovca"
              />
            </div>
            <div style={this.styles2}>
              <h6>TELEFON:</h6>
              <Form.Control
                type="text"
                id="telefonI"
                placeholder="Telefon lovca"
              />

              <h6>MJESTO ROĐENJA:</h6>
              <Form.Control
                type="text"
                id="mjestoRodjenjaI"
                placeholder="Mjesto rođenja lovca"
              />
              <h6>ADRESA: </h6>
              <Form.Control
                type="text"
                id="adresaI"
                placeholder="Adresa lovca"
              />
              <h6> Revir i Sekcija</h6>
              <Input id="revir/sekcija2" as="select" style={this.styles3}>
                <option value="4-1">Ozren-Boljanić</option>{" "}
                <option value="4-16">Ozren-Lipac</option>
                <option value="4-30">Ozren-Tekućica</option>
                <option value="4-23">Ozren-Pridjel</option>
                <option value="4-8">Ozren-RUJ Paklenica</option>
                <option value="4-28">Ozren-SOT Osojnica</option>
                <option value="4-3">Ozren-Brezici</option>
                <option value="" disabled>
                  . . . . . . . . . . . . .
                </option>
                <option value="1-27">Krnjin-Stanovi</option>
                <option value="1-31">Krnjin-Velika Bukovica</option>
                <option value="1-13">Krnjin-Kladari</option>
                <option value="1-26">Krnjin-Kotorsko</option>
                <option value="1-17">Krnjin-Ljeskove Vode</option>
                <option value="1-11">Krnjin-Grabovica</option>
                <option value="1-24">Krnjin-Putnikovo Brdo</option>
                <option value="" disabled>
                  . . . . . . . . . . . . .
                </option>
                <option value="3-14">Trebava-Kostajnica</option>
                <option value="3-4">Trebava-Bušletić</option>
                <option value="3-34">Trebava-Sjenina</option>
                <option value="3-6">Trebava-Donja Paležnica</option>
                <option value="3-7">Trebava-Gornja Paležnica</option>
                <option value="3-10">Trebava-Zelinja</option>
                <option value="3-15">Trebava-Kožuhe</option>
                <option value="3-19">Trebava-Osječani</option>
                <option value="3-20">Trebava-Osječanske Čivčije</option>
                <option value="3-36">Trebava-Skipovac</option>
                <option value="" disabled>
                  . . . . . . . . . . . . .
                </option>
                <option value="5-18">Vučijak-Majevac</option>
                <option value="5-22">Vučijak-Podnovlje</option>
                <option value="5-35">Vučijak-Ritešić</option>
                <option value="" disabled>
                  . . . . . . . . . . . . .
                </option>
                <option value="6-32">Lovac gost udruženja</option>
                <option value="7-33">Podmladak</option>
              </Input>
              <h6>ZANIMANJE:</h6>
              <Input id="zanimanjeI" as="select" style={this.styles3}>
                {this.state.zanimanje.map((ss) => {
                  return <option value={ss.sifra}>{ss.naziv}</option>;
                })}
              </Input>

              <h6>FIRMA: </h6>
              <Form.Control type="text" id="firmaI" placeholder="Firma lovca" />

              <h6>DATUM ČLANSTVA:</h6>
              <DatePickerInput
                id="datumClanstvaI"
                defaultValue={this.state.day}
                //locale="es"
                onChange={this.datumClanstva}
                style={this.styles3}
              />
            </div>
            <div style={this.styles2}>
              <h6>DATUM ROĐENJA:</h6>
              <DatePickerInput
                id="datumRodjenjaI"
                defaultValue={this.state.day}
                //locale="es"
                onChange={this.datumRodjenja}
                style={this.styles3}
              />

              <h6>DATUM LOVAČKOG ISPITA:</h6>
              <DatePickerInput
                id="datumLovackogI"
                defaultValue={"1900-11-11"}
                //locale="es"
                onChange={this.datumLovackog}
                style={this.styles3}
              />
              <h6>INVALIDITET: </h6>
              <Input id="invaliditetI" as="select" style={this.styles3}>
                {this.state.invaliditet.map((ss) => {
                  return <option value={ss.sifra}>{ss.naziv}</option>;
                })}
              </Input>

              <h6>NACIJA:</h6>
              <Input id="nacijaI" as="select" style={this.styles3}>
                {this.state.nacija.map((ss) => {
                  return <option value={ss.sifra}>{ss.naziv}</option>;
                })}
              </Input>

              <h6>MJESTO STANOVANJA:</h6>
              <Form.Control
                id="mjestoStanovanjaI"
                type="text"
                placeholder="Mjesto stanovanja lovca"
              />
              <h6>STRUČNA SPREMA:</h6>
              <Input id="spremaI" as="select" style={this.styles3}>
                {this.state.sprema.map((ss) => {
                  return <option value={ss.sifra}>{ss.naziv}</option>;
                })}
              </Input>

              <h5 className="mt-4">šifra:{this.state.zadnjiBroj} </h5>
              <Button
                className="m-2 btn btn-success centerButton"
                size="sm"
                type="submit"
              >
                Upiši lovca
              </Button>
            </div>
          </Form>
        </div>
      ),
    });
  };

  proba = (ev) => {
    ev.preventDefault();

    let ime = document.getElementById("imeI").value;

    ime = ime.toLowerCase();
    let ime1 = ime.slice(0, 1);
    let ime2 = ime.slice(1);
    ime = ime1.toUpperCase() + ime2;

    let prezime = document.getElementById("prezimeI").value;

    prezime = prezime.toLowerCase();
    let prezime1 = prezime.slice(0, 1);
    let prezime2 = prezime.slice(1);
    prezime = prezime1.toUpperCase() + prezime2;

    let funkcija = document.getElementById("funkcijaI").options[
      document.getElementById("funkcijaI").selectedIndex
    ].value;
    let imeRoditelja = document.getElementById("imeRoditeljaI").value;

    imeRoditelja = imeRoditelja.toLowerCase();
    let imeRoditelja1 = imeRoditelja.slice(0, 1);
    let imeRoditelja2 = imeRoditelja.slice(1);
    imeRoditelja = imeRoditelja1.toUpperCase() + imeRoditelja2;

    let jmbg = document.getElementById("jmbgI").value;
    let email = document.getElementById("emailI").value;
    let telefon = document.getElementById("telefonI").value;

    let mjestoRodjenja = document.getElementById("mjestoRodjenjaI").value;

    let adresa = document.getElementById("adresaI").value;

    let zanimanje = document.getElementById("zanimanjeI").options[
      document.getElementById("zanimanjeI").selectedIndex
    ].value;
    let firma = document.getElementById("firmaI").value;
    let datumClanstva =
      this.state.datumClanstva.split("-")[2] +
      "." +
      this.state.datumClanstva.split("-")[1] +
      "." +
      this.state.datumClanstva.split("-")[0];
    let datumRodjenja =
      this.state.datumRodjenja.split("-")[2] +
      "." +
      this.state.datumRodjenja.split("-")[1] +
      "." +
      this.state.datumRodjenja.split("-")[0];

    let datumLovackog =
      this.state.datumLovackog.split("-")[2] +
      "." +
      this.state.datumLovackog.split("-")[1] +
      "." +
      this.state.datumLovackog.split("-")[0];

    if (datumLovackog == "11.11.1900") {
      datumLovackog = "";
    }
    let invaliditet = document.getElementById("invaliditetI").options[
      document.getElementById("invaliditetI").selectedIndex
    ].value;
    let nacija = document.getElementById("nacijaI").options[
      document.getElementById("nacijaI").selectedIndex
    ].value;
    let mjestoStanovanja = document.getElementById("mjestoStanovanjaI").value;

    let sprema = document.getElementById("spremaI").options[
      document.getElementById("spremaI").selectedIndex
    ].value;
    let status = document.getElementById("statusI").options[
      document.getElementById("statusI").selectedIndex
    ].value;

    let sifra = this.state.zadnjiBroj;
    let sifraString = sifra.toString();
    let lozinkaBroj = sifraString;

    let revir = document
      .getElementById("revir/sekcija2")
      .options[
        document.getElementById("revir/sekcija2").selectedIndex
      ].value.split("-")[0];
    let sekcija = document
      .getElementById("revir/sekcija2")
      .options[
        document.getElementById("revir/sekcija2").selectedIndex
      ].value.split("-")[1];

    if (jmbg.length == 13) {
      if (window.confirm("Upisi lovca ")) {
        dodajKorisnika(sifra + "@fazandoboj.com", jmbg).then((uu) => {
          upisiClana(
            status,
            ime,
            prezime,
            sifraString,
            funkcija,
            imeRoditelja,
            jmbg,
            email,
            telefon,
            mjestoRodjenja,
            adresa,
            revir,
            sekcija,
            zanimanje,
            firma,
            datumClanstva,
            datumRodjenja,
            invaliditet,
            nacija,
            mjestoStanovanja,
            sprema,
            lozinkaBroj,
            uu.user.uid,
            datumLovackog
          ).then(() => {
            uvecajBroj(sifra).then(() => {
              zadnjiBroj().then((snapshot) => {
                let broj = snapshot.val().broj;
                broj = Number(broj) + 1;
                this.setState({ zadnjiBroj: broj });
                this.dajFormu();

                alert("Lovac broj: " + sifra + " upisan!");
                document.getElementById("imeI").value = "";
                document.getElementById("prezimeI").value = "";
                document.getElementById("jmbgI").value = "";
                document.getElementById("imeRoditeljaI").value = "";
                document.getElementById("emailI").value = "";
                document.getElementById("telefonI").value = "";
                document.getElementById("mjestoRodjenjaI").value = "";
                document.getElementById("adresaI").value = "";
                document.getElementById("firmaI").value = "";
                document.getElementById("mjestoStanovanjaI").value = "";
                document.getElementById("statusI").value = 1;
                document.getElementById("funkcijaI").value = 0;
                document.getElementById("revir/sekcija2").value = "4-1";
                document.getElementById("zanimanjeI").value = 1;
                document.getElementById("invaliditetI").value = 0;
                document.getElementById("nacijaI").value = 1;
                document.getElementById("spremaI").value = 1;
                document.getElementById(
                  "datumRodjenjaI"
                ).defaulValue = this.state.day;
                document.getElementById(
                  "datumClanstvaI"
                ).defaulValue = this.state.day;
                document.getElementById(
                  "datumLovackogI"
                ).defaulValue = this.state.day;
              });
            });
          });
        });
      }
    } else {
      alert("Neispravan jmbg");
    }
  };

  render() {
    return <div style={this.glavni}>{this.state.forma}</div>;
  }
}

export default NoviClan;
