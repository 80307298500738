import firebase from "firebase/app";

import "firebase/database";
import "firebase/auth";
import "firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyD_VBKVRnUZo-zFGOrSp_a3AtVaPSDztoc",
  authDomain: "chat-55edd.firebaseapp.com",
  databaseURL: "https://chat-55edd.firebaseio.com",
  projectId: "chat-55edd",
  storageBucket: "chat-55edd.appspot.com",
  messagingSenderId: "816579591314",
  appId: "1:816579591314:web:593e075ba0587523e77a4b"
};
// Initialize Firebase

const drugi = firebase.initializeApp(firebaseConfig, "drugi");

/**/
//secondUser.auth().signOut();

export default drugi;
